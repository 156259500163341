import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5a6d55cf = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _276ca791 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _28053b92 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _517ec23f = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _001e22b1 = () => interopDefault(import('../pages/discounts.vue' /* webpackChunkName: "pages/discounts" */))
const _0a0e29d0 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _23d1450b = () => interopDefault(import('../pages/index-old.vue' /* webpackChunkName: "pages/index-old" */))
const _3f4bb80e = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _bb265ece = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _1e185c34 = () => interopDefault(import('../pages/pages/index.vue' /* webpackChunkName: "pages/pages/index" */))
const _6272c48a = () => interopDefault(import('../pages/payment-methods.vue' /* webpackChunkName: "pages/payment-methods" */))
const _ac11fe70 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _21b79a18 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _59a63b48 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _4fa9caf4 = () => interopDefault(import('../pages/refund-policy.vue' /* webpackChunkName: "pages/refund-policy" */))
const _6db10c64 = () => interopDefault(import('../pages/return-policy.vue' /* webpackChunkName: "pages/return-policy" */))
const _5a5aa70d = () => interopDefault(import('../pages/searchResults.vue' /* webpackChunkName: "pages/searchResults" */))
const _5a1189aa = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _1d061eaf = () => interopDefault(import('../pages/terms-conditions.vue' /* webpackChunkName: "pages/terms-conditions" */))
const _17776310 = () => interopDefault(import('../pages/shop/cart.vue' /* webpackChunkName: "pages/shop/cart" */))
const _b56d84c4 = () => interopDefault(import('../pages/shop/checkout.vue' /* webpackChunkName: "pages/shop/checkout" */))
const _23a00af9 = () => interopDefault(import('../pages/shop/list/index.vue' /* webpackChunkName: "pages/shop/list/index" */))
const _5c02bf00 = () => interopDefault(import('../pages/shop/orders/index.vue' /* webpackChunkName: "pages/shop/orders/index" */))
const _6444f478 = () => interopDefault(import('../pages/shop/category/_id/index.vue' /* webpackChunkName: "pages/shop/category/_id/index" */))
const _65190164 = () => interopDefault(import('../pages/shop/list/_id/index.vue' /* webpackChunkName: "pages/shop/list/_id/index" */))
const _20d9092a = () => interopDefault(import('../pages/shop/orders/_id/index.vue' /* webpackChunkName: "pages/shop/orders/_id/index" */))
const _1421b406 = () => interopDefault(import('../pages/shop/orders/_id/invoice.vue' /* webpackChunkName: "pages/shop/orders/_id/invoice" */))
const _009a7741 = () => interopDefault(import('../pages/blog/_id/index.vue' /* webpackChunkName: "pages/blog/_id/index" */))
const _7ec9f704 = () => interopDefault(import('../pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _442589d1 = () => interopDefault(import('../pages/pages/_id/index.vue' /* webpackChunkName: "pages/pages/_id/index" */))
const _6085e903 = () => interopDefault(import('../pages/products/_id/index.vue' /* webpackChunkName: "pages/products/_id/index" */))
const _174c80d6 = () => interopDefault(import('../pages/shop/_id/index.vue' /* webpackChunkName: "pages/shop/_id/index" */))
const _e817589e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _5a6d55cf,
    name: "about-us"
  }, {
    path: "/cart",
    component: _276ca791,
    name: "cart"
  }, {
    path: "/checkout",
    component: _28053b92,
    name: "checkout"
  }, {
    path: "/contact",
    component: _517ec23f,
    name: "contact"
  }, {
    path: "/discounts",
    component: _001e22b1,
    name: "discounts"
  }, {
    path: "/home",
    component: _0a0e29d0,
    name: "home"
  }, {
    path: "/index-old",
    component: _23d1450b,
    name: "index-old"
  }, {
    path: "/inspire",
    component: _3f4bb80e,
    name: "inspire"
  }, {
    path: "/orders",
    component: _bb265ece,
    name: "orders"
  }, {
    path: "/pages",
    component: _1e185c34,
    name: "pages"
  }, {
    path: "/payment-methods",
    component: _6272c48a,
    name: "payment-methods"
  }, {
    path: "/privacy-policy",
    component: _ac11fe70,
    name: "privacy-policy"
  }, {
    path: "/products",
    component: _21b79a18,
    name: "products"
  }, {
    path: "/profile",
    component: _59a63b48,
    name: "profile"
  }, {
    path: "/refund-policy",
    component: _4fa9caf4,
    name: "refund-policy"
  }, {
    path: "/return-policy",
    component: _6db10c64,
    name: "return-policy"
  }, {
    path: "/searchResults",
    component: _5a5aa70d,
    name: "searchResults"
  }, {
    path: "/shop",
    component: _5a1189aa,
    name: "shop"
  }, {
    path: "/terms-conditions",
    component: _1d061eaf,
    name: "terms-conditions"
  }, {
    path: "/shop/cart",
    component: _17776310,
    name: "shop-cart"
  }, {
    path: "/shop/checkout",
    component: _b56d84c4,
    name: "shop-checkout"
  }, {
    path: "/shop/list",
    component: _23a00af9,
    name: "shop-list"
  }, {
    path: "/shop/orders",
    component: _5c02bf00,
    name: "shop-orders"
  }, {
    path: "/shop/category/:id",
    component: _6444f478,
    name: "shop-category-id"
  }, {
    path: "/shop/list/:id",
    component: _65190164,
    name: "shop-list-id"
  }, {
    path: "/shop/orders/:id",
    component: _20d9092a,
    name: "shop-orders-id"
  }, {
    path: "/shop/orders/:id/invoice",
    component: _1421b406,
    name: "shop-orders-id-invoice"
  }, {
    path: "/blog/:id",
    component: _009a7741,
    name: "blog-id"
  }, {
    path: "/orders/:id",
    component: _7ec9f704,
    name: "orders-id"
  }, {
    path: "/pages/:id",
    component: _442589d1,
    name: "pages-id"
  }, {
    path: "/products/:id",
    component: _6085e903,
    name: "products-id"
  }, {
    path: "/shop/:id",
    component: _174c80d6,
    name: "shop-id"
  }, {
    path: "/",
    component: _e817589e,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
