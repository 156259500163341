
// state
const state = {

}

// getters
const getters = {
  // Checkout is enabeled only if the cart contains products.
  enableCheckout: (state, getters) => {
    let cartProducts =
      (getters.activeCart && getters.activeCart.cartTO) ? getters.activeCart.cartTO.products : []
    if (cartProducts && cartProducts.length > 0) {
      return true
    }
    return false
  },
  sortedCartProducts: (state, getters) => {
    // console.log('Getter sortedCartProducts')
    let cart = getters.activeCart
    if (cart && cart.cartTO && cart.cartTO.products) {
      let products = cart.cartTO.products
      // Clone products array so that orignal object is not altered as its reactive.
      let sortedProducts = products.slice()
      // Sort products alphabetically.
      sortedProducts = sortedProducts.sort(function (a, b) {
        let name1 = a.productTO.name
        let name2 = b.productTO.name
        if (name1 < name2) {
          return -1;
        }
        if (name1 > name2) {
          return 1;
        }
        return 0;
      })
      return sortedProducts
    }
    return []
  }
}

const mutations = {

}

const actions = {
    updateQuantity({context, dispatch}, product) {
    // console.log("Update Quantity from Cart", product)
    let operation = "QUANTITY_CHANGE_CART"
    dispatch('cartUpdate', {"product": product, "operation": operation})
  },
  deleteProduct ({context, dispatch}, product) {
    // console.log('Delete', product)
    let operation = "REMOVE_FROM_CART"
    dispatch('cartUpdate', {"product": product, "operation": operation})
  },

}

export default {
    state,
    getters,
    mutations,
    actions
}
