import constants from '~/components/constants'
import axios from 'axios'

const state = {
    shippingAddress: [],
    loginDialog: false,
    registerBtnLoading: true,
    loginBtnLoader: false,
    logoutBtnLoader: false,
    login: false,
    logout: false
}

const getters = {
    // registerBtnLoading: state => {
    //     return state.registerBtnLoading
    // }
    getLoginDialog: (state, getters) => {
        return state.loginDialog
    },
    getLoginState: state => {
        return state.login
    },
    getLogoutState: state => {
        return state.logout
    },
    getLoginBtnLoader: state => {
        return state.loginBtnLoader
    },
    getLogoutBtnLoader: state => {
        return state.logoutBtnLoader
    }
}

const mutations = {
    setLoginDialog(state, payload) {
        // console.log('Set Login Dialog', payload)
        state.loginDialog = payload
    },
    setRegisterBtnLoading: (state, payload) => {
        state.registerBtnLoading = payload
    },
    setLoginBtnLoader: (state, payload) => {
        state.loginBtnLoader = payload
    },
    setLogoutBtnLoader: (state, payload) => {
        state.logoutBtnLoader = payload
    },
    setLoginState: (state, payload) => {
        console.log('SetLogin Store', payload)
        state.login = payload
    },
    setLogoutState: (state, payload) => {
        // console.log('SetLogout Store', payload)
        state.logout = payload
    },
    setLogoutBtnLoader: (state, payload) => {
        // console.log('SetLogout Btn Loader Store', payload)
        state.logoutBtnLoader = payload
    }
}

const actions = {
    registerUser({commit, getters, dispatch}, payload) {
      return new Promise((resolve, reject) => {
        let url = constants.url + '/api/customer'
        let data = {
            'email': payload.email,
            'password': payload.password,
            'martId': getters.activeMartInfo.id,
            'temp': false,
            'name': '',
            'address': [{
                'city': '',
                'country': '',
                'email': '',
                'fullName': '',
                'hNo': '',
                'landmark': '',
                'locality': '',
                'mobile': '',
                'pin': '',
                'state': ''
            }],
            'billingAddress': [{
                'city': '',
                'country': '',
                'email': '',
                'fullName': '',
                'hNo': '',
                'landmark': '',
                'locality': '',
                'mobile': '',
                'pin': '',
                'state': ''
            }]
        }
        // console.log('Data from store register user', data)
        axios.put(url, data, { withCredentials: true })
        .then(response => {
            // console.log('User Register Store', response.data)
            if(response.data) {
                commit('setRegisterBtnLoading', false)
                dispatch('userLogin', response.data)
                // setting global snackbar in main store after user is registered..
                let snackBar = {
                    state:  true, 
                    message: 'You are Registered & Logged in now..'
                }
                commit('setSnackBar', snackBar)
            }
            resolve()
        }).catch(err => {
            // console.log('Login Error', err.response.data)
            let type = err.response.data.type
            if (type == 'CUSTOMER-EXIST') {
                let snackBar = {
                    state: true,
                    message: 'User with this email ID already exists.'
                }
                commit('setSnackBar', snackBar)
                commit('setLoginBtnLoader', false)
                reject(type)
            }
        })
      })
    },
    userLogin({
      commit,
      dispatch,
      state,
      getters
    }, data) {
      return new Promise((resolve, reject) => {
        let loginUrl = constants.url + '/api/customer/login'
        let martId = this.getters.activeMartInfo
        // console.log('MartId in store login', martId)
        let loginData = {
          "email": data.email,
          "password": data.password,
          "martId": martId.id,
          "newPassword": data.newPassword,
          "otp": data.otp
        }
        // console.log('Login Data in store', loginData)

        axios.post(loginUrl, loginData, {
            withCredentials: true
          })
          .then(response => {
            console.log('Login Response', response.data)
            commit('setCustomer', response.data)
            commit('setLoginBtnLoader', false)
            commit('setLoginDialog', false)

            // Set userId cookie on Login
            let data = {};
            data.cname = constants.loginCookieName;
            data.cvalue = response.data.id;
            data.exdays = constants.loginCookieValid;
            dispatch("setCookie", data);

            dispatch('getCart')
            // setting global snackbar in main store after user is logged in..
            let snackBar = {
              state: true,
              message: 'You are Logged in..'
            }
            commit('setSnackBar', snackBar)
            resolve()
          }).catch(err => {
              console.log('Login Error', err.response.data)
              let type = err.response.data.type
              if (type == 'PASS-INCORRECT') {
                let snackBar = {
                  state: true,
                  message: 'Check Password'
                }
                commit('setSnackBar', snackBar)
                commit('setLoginBtnLoader', false)
              }
              if (type == 'NO-USER-EXIST') {
                let snackBar = {
                  state: true,
                  message: data.otp ? 'Incorrect OTP entered' : 'User does not exist'
                }
                commit('setSnackBar', snackBar)
                commit('setLoginBtnLoader', false)
              }
              reject(type)
            }
          )
      })
    },
        checkLogin({commit, getters, dispatch}) {
        let martId = getters.activeMartInfo
        let checkLoginUrl = constants.url + '/api/customer/check-login?martId=' + martId.id
        axios.get(checkLoginUrl, { withCredentials: true })
        .then(response => {
          if(response.data) {
            let loginUser = response.data
            // console.log('Check Login after Logout from customer store', loginUser)
            commit('setCustomer', loginUser)

            // Set userId cookie
            let data = {};
            data.cname = constants.loginCookieName;
            data.cvalue = response.data.id;
            data.exdays = constants.loginCookieValid;
            dispatch("setCookie", data);
          }
        })
      },
    userLogout({commit, getters, dispatch}) {
      return new Promise((resolve, reject) => {
        commit('setLogoutBtnLoader', true)
        let martId = getters.activeMartInfo
        // console.log('Mart ID', martId)
        let logoutUrl = constants.url + '/api/customer/logout?martId=' + martId.id
        axios.get(logoutUrl, { withCredentials: true })
        .then(response => {
            // console.log('Logout Response from customer store', response.data)
            commit('setLogoutState', false)
            commit('setLoginState', true)
            commit('setLogoutBtnLoader', false)
            commit('setCart', null)

            // Remove userId cookie on Logout
            let data = {};
            data.cname = constants.loginCookieName;
            data.cvalue = "";
            data.exdays = -10;
            dispatch("setCookie", data);

            dispatch('checkLogin')
            // setting global snackbar in main store after user is logged out..
            let snackBar = {
                state:  true, 
                message: 'You are logged out..'
            }
            commit('setSnackBar', snackBar)
            resolve()
        })
      }) 
    },
    sendOtp({commit, getters, dispatch}, email) {
      return new Promise((resolve, reject) => {
        let martId = getters.activeMartInfo
        let passwordResetUrl = constants.url + '/api/customer/forget-password?martId=' + martId.id + '&email=' + email
        axios.put(passwordResetUrl, {withCredentials: true})
        .then(response => {
          // console.log('Response from Password Reset action from sessions store', response.data)
          let snackBar = {
            state:  true, 
            message: 'OTP sent via Email to Reset Password.'
          }
          commit('setSnackBar', snackBar)
          resolve()
        }).catch(err => {
          // console.log('Login Error', err.response.data)
          let type = err.response.data.type
          if (type == 'NO-USER-EXIST') {
            let snackBar = {
              state: true,
              message: 'User with this email ID does not exist.'
            }
            commit('setSnackBar', snackBar)
            reject()
          }
        })
      })
  },
  setCookie({}, data) {
    let cname = data.cname;
    let cvalue = data.cvalue;
    let exdays = data. exdays;

    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires=" + d.toGMTString();
    console.log('setting cookie:: ' + cname + "=" + cvalue + ";" + expires + ";path=/")
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },
  getCookie({}, cname) {
    return new Promise((resolve, reject) => {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          resolve(c.substring(name.length, c.length));
        }
      }
      resolve("");
    })
  }
}

export default {
    // namespaced: true,
    state,
    getters,
    mutations, 
    actions
}