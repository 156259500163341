import constants from '~/components/constants'
import axios from 'axios'

const state = {
  // validations
    isBillingAddressValid: false,
    isShippingAddressValid: false,
    // checkoutData: null,
    // productData: [],
    totalPrice: null,
    grandTotal: null,
    // data array which sent at time of checkout
    orderData: [],
    checkoutDialog: false,
    OrderSuccessAlert: false,
    appliedDiscount: null,
    discountLoader: false,
    checkoutData: {},
    pendingOrder: null,
    globalValidateModal: false,
    // being used by CustomerComments.vue component in checkout folder, 
    // for special instructions at checkout
    custComment: ''
  }

  // getters
  const getters = { 
    activeCheckoutData: (state) => {
        return state.checkoutData
      },
      getCartProducts: (state, getters, rootState) => {
        state.orderData = []
        let orderTO = state.checkoutData.orderProductTOs
        // let orderProductTO = []
        let selectedCurrency = state.selectedCurrency
        // let finalPrice = state.checkoutData.grandTotal
        // let price = state.checkoutData.total
        // let quantity = state.checkoutData.quantity
        if (orderTO) {
          orderTO.forEach(orderProductTO => {
            // orderTO.push(element)
            // console.log('ProductTO', element, selectedCurrency, discount)
            let orderProduct = {
              "productTO": orderProductTO.productTO,
              "currency": orderProductTO.currency,
              "discount": Math.round(orderProductTO.discount),
              "finalPrice": Math.round(orderProductTO.finalPrice),
              "price": Math.round(orderProductTO.price),
              "quantity": orderProductTO.quantity,
              "tax": Math.round(orderProductTO.tax),
              "taxRules": orderProductTO.taxRules
            }
            // console.log('Order Data from store', orderProduct)
            state.orderData.push(orderProduct)
          })
        }
      },
      pendingOrder: (state) => {
        return state.pendingOrder
      },
      getisBillingAddressValid: (state, getters, rootState) => {
        // return state.isBillingAddressValid
        let billingAddress = rootState.customer && rootState.customer.billingAddress ? rootState.customer.billingAddress[0] : ''
        // console.log('Billing Address is Valid from Store', billingAddress)
        if (billingAddress) {
          if (billingAddress.city === "" ||
            billingAddress.state === "" ||
            billingAddress.pin === "" ||
            billingAddress.country === "" ||
            billingAddress.hNo === "" ||
            billingAddress.mobile === "" ||
            billingAddress.fullName === ""
          ) {
            return false
          } else {
            return true
          }
        }
        return false
      },
      getisShippingAddressValid: (state, getters, rootState) => {
        // return state.isaddressValid
        let shippingAddress = rootState.customer && rootState.customer.address ? rootState.customer.address[0] : ''
        if (shippingAddress) {
            // console.log('addresss is Valid from Store', shippingAddress)
            if (shippingAddress.city === "" ||
            shippingAddress.state === "" ||
            shippingAddress.pin === "" ||
            shippingAddress.country === "" ||
            shippingAddress.hNo === "" ||
            shippingAddress.mobile === "" ||
            shippingAddress.fullName === ""
          ) {
            return false
          } else {
            return true
          }
         }
         return false
      },
      getGlobalValidateModal: (state) => {
        return state.globalValidateModal
      },
      getCustomerComments: (state) => {
        return state.custComment
      }
  }
  // mutations
  const mutations = { 
    setCheckoutData (state, payload) {
        state.checkoutData = payload
      },
    setTotalPrice (state, payload) {
        state.totalPrice = payload
      },
    setGrandTotal (state, payload) {
        state.grandTotal = payload
      },
    setOrderData (state, payload) {
      // console.log('SetOrderData Mutation', payload)
      state.orderData.push(payload)
      },
    setAppliedDiscount (state, payload) {
      state.appliedDiscount = payload
      },
    setCheckoutDialog (state, payload) {
      state.checkoutDialog = payload
    },
    setOrderSuccessAlert (state, payload) {
      state.OrderSuccessAlert = payload
    },
    setPendingOrder (state, payload) {
      state.pendingOrder = payload
      // console.log('setPendingOrder', payload, state.pendingOrder)
    },
    setBillingAddressValidation (state, payload) {
      state.isBillingAddressValid = payload
      // console.log('setPendingOrder', payload, state.pendingOrder)
    },
    setGlobalValidateModal (state, payload) {
      state.globalValidateModal = payload
    },
    setCustomerComments (state, payload) {
      state.custComment = payload
    }
  }
  // actions
  const actions = { 
    createOrder ({commit, state, getters, rootState}, data) {
        // console.log('Create Order', data)
        state.checkoutDialog = true
        let selectedCurrency = this.state.selectedCurrency
        let martId = this.state.martInfo.id
        let customer = this.state.customer
        let custComment = getters.getCustomerComments
        // console.log('Customer Comments sent in createOrder in store/actions', custComment)
        // let customerId = this.state.customer.id
        let selectedPaymentMethod = this.state.selectedPaymentMethod.gateway
        // let orderStatus = selectedPaymentMethod == "COD" ? "CONFIRMED" : "GENERATED"
        let orderStatus = data.orderStatus
        let billingAddress = this.state.customer.billingAddress[0]
        let shippingAddress = this.state.customer.address[0]
        getters.getCartProducts
        let products = state.orderData//rootState.cart.cartTO.products
        // console.log('OrderData in create Order', products)
        let totalPrice = Math.round(state.checkoutData.total)
        let grandTotal = Math.round(state.checkoutData.grandTotal)
        let tax = Math.round(state.checkoutData.tax)
        let discount = Math.round(state.checkoutData.discount)
        let couponApplied = state.appliedDiscount
        let paymentDetails = {
          "paymentGateway": data.paymentGateway,
          'invoiceId': data.invoiceId
        }
        // let orderDate = Date()
        // console.log('checkout actions', state.orderData)
        let checkoutData = {
            "amount": totalPrice,
            "tax": tax,
            "discount": discount,
            "grandTotal": grandTotal,
            "currency": selectedCurrency,
            "billingAddress": billingAddress,
            "shippingAddress": shippingAddress,
            "customerTO": customer,
            "martId": martId,
            "paymentGateway": selectedPaymentMethod,
            "paymentDetails": paymentDetails,
            "status": orderStatus,
            "products": products,
            "custComment": custComment,
            "couponApplied": couponApplied,
            }
            // console.log('CheckoutData', checkoutData)
            let checkoutUrl = constants.url + '/api/order'
            axios.put(checkoutUrl, checkoutData, { withCredentials: true })
            .then(response => {
              this.state.cart.cartTO.products = []
              // console.log('Checkout Response', response.data)
              // state.orderData = []
              // state.checkoutDialog = false
              // on order page to show alert of successfull order creation
              // state.OrderSuccessAlert = true
              // navigating to order details page
              // this.$router.push('orders/' + response.data.id)

              if (orderStatus != 'GENERATED') {
                // navigating to order details page
                state.checkoutDialog = false
                state.OrderSuccessAlert = true
                this.$router.push('/shop/orders/' + response.data.id)
              } else {
                // console.log('commit - setPendingOrder', response.data)
                // TODO set pending order is not needed any more.
                commit('setPendingOrder', response.data)
              }
            })
    },
    getDiscountInfo({ commit, dispatch },  code) {
      let martId = this.state.martInfo.id
      let couponUrl = constants.url + '/api/coupon?martId=' + martId + '&code=' + code
      axios.get(couponUrl, { withCredentials: true })
      .then(response => {
        // console.log('Discount Get Store', response.data.couponTOs[0])
        let discountData = response.data.couponTOs[0]
        commit('setAppliedDiscount', discountData)
        state.discountLoader = false
        // dispatch('getCart')
        dispatch('getCheckoutData')
      })
    },
    getCheckoutData({commit, state, getters}) {
      this.state.checkoutData = null
      let cart = this.state.cart
      let mart = this.state.martInfo
      let customer = this.getters.activeCustomer
      let shippingAddress = (customer.temp == false) ? customer.address[0] : {}
      let selectedCurrency = this.state.selectedCurrency
      let coupon = state.appliedDiscount ? state.appliedDiscount : null
      console.log('Test Data', cart, mart, customer.temp, shippingAddress, coupon, selectedCurrency)
        let requestData = {
          cartProducts: cart.cartTO.products,
          couponApplied: coupon,
          selectedCurrency: selectedCurrency,
          taxRuleTOs: mart.taxRules,
          shippingAddr: shippingAddress
      }
      // console.log(requestData.shippingAddr)
      console.log('Req Obj', requestData)
      let url = constants.url + '/api/order/calc' + '?martId=' + mart.id  + "&customerId=" + customer.id
      axios.put(url, requestData, { withCredentials: true })
            .then(response => {
                console.log('Response Object CheckoutData', response.data)
                commit('setCheckoutData', response.data)
                // total: 1600
                // discount: 160
                // tax: 72
                // grandTotal: 1512
                // orderProductTOs: Array(1)
                //     0:
                //     currency: "INR"
                //     price: 800
                //     discount: 80
                //     tax: 36
                //     finalPrice: 756
                //     quantity: 2
                //     taxRules: [{…}]
                //     productTO: {id: 6579477580611584, martId: 5066549580791808, name: "Prod x", description: "", categoryTOs: Array(1), …}
            })
            .catch(err => {
                console.log('Error', err)
            })
    },
    cancelOrder({commit, state, getters}, payload) {
      return new Promise((resolve, reject) => {
        if (payload) {
          let martId = payload.martId;
          let orderId = payload.orderId;
          let customerId = payload.customerId;
          if (martId && customerId && orderId) {
            let url =
              constants.url + '/api/order/cancel'
              + '?martId=' + martId
              + "&orderId=" + orderId
              + "&customerId=" + customerId;
            axios.get(url, { withCredentials: true })
              .then(response => {
                resolve(response);
              })
              .catch(err => {
                reject(err);
              })
          } else {
            reject('Incomplete parameters');
          }
        } else {
          reject('Incomplete parameters');
        }
      })
    }
  }

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }