<template>
  <div :show="show" class="view-wrapper">
    <div class="toast-wrapper" shadow-lg>
      <div class="header">
        <div style="cursor: pointer; margin-right: 8px; margin-top: 0.2px" @click="onClickClose">
          <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 0 24 24" width="22px" fill="#000000">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
          </svg>
        </div>
        <p style="margin: 0; padding: 0; font-size: 14px; font-weight: bold; color: #000;">
          {{ config.title }}
        </p>
      </div>
      <div v-if="config.message" class="message">
        <p style="font-size: 12px; color: #000; margin: 0; padding: 0;">
          {{ config.message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const defaultConfig = () => {
  return {
    title: '',
    message: '',
    callback: () => {}
  }
}

export default {
  data () {
    return {
      show: false,
      config: Object.assign({}, defaultConfig()),
      timer: null
    }
  },
  watch: {
    show (value) {
      if (!value) {
        const config = defaultConfig()
        Object.keys(config).forEach(key => {
          this.$set(this.config, key, config[key])
        })
      }
    }
  },
  created () {
    this.$root.$on('toast/show', this.showToast)
  },
  methods: {
    showToast (props) {
      clearInterval(this.timer)
      let { title, message, callback, timeout } = props || {}
      if (!(title)) { return }

      this.$set(this.config, 'title', title)
      if (message) {
        this.$set(this.config, 'message', message)
      }
      if (typeof callback === 'function') {
        this.$set(this.config, 'callback', callback)
      }
      if (isNaN(timeout)) {
        timeout = 0
      }
      this.show = true

      this.timer = setInterval(() => {
        this.show = false
      }, timeout || 3000)
    },
    onClickClose () {
      if (typeof this.config.callback === 'function') {
        this.config.callback(true)
      }
      this.show = false
    }
  }
}
</script>

<style scoped>
  .view-wrapper {
    position: fixed;
    top: 54px;
    right: -100%;
    background: transparent;
    width: 100%;
    padding: 10px 20px 0 10px;
    max-width: 320px;
    z-index: 600 !important;
    transition: right 0.2s ease-in-out;
  }
  .view-wrapper[show] {
    right: 0;
  }
  .toast-wrapper {
    width: 100%;
    height: 100%;
    background: #ffde7b;
    padding: 10px 12px 10px 12px;
    border-radius: 4px;
    border-top-style: solid;
    border-top-width: 4px;
    border-top-color: #D5BBBB;
  }
  .toast-wrapper[shadow] {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  .toast-wrapper[shadow-lg] {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  .toast-wrapper[shadow-xl] {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
  .toast-wrapper[shadow-2xl] {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  .toast-wrapper[shadow-3xl] {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
  .toast-wrapper > .header {
    display: flex;
    align-items: center;
    height: 28px;
  }
  .toast-wrapper > .message {
    margin-top: 8px;
    height: 100%;
    max-height: 100px;
    overflow-y: auto;
  }
</style>
