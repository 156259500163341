import constants from "~/components/constants";
import firebase, {db} from "@/services/fireinit";
import axios from "axios";

// state
const state = {
  productReviews: [],
  reviewCount: 0,
  size: 3
};

// getters
const getters = {
  getProductReviews: (state) => {
    return state.productReviews;
  },
  getReviewCount: (state) => {
    return state.reviewCount;
  },
  getReviewPageSize: (state) => {
    return state.size;
  }
};

// mutations
const mutations = {
  setProductReviews: (state, payload) => {
    state.productReviews = payload;
  },
  setReviewsCount: (state, payload) => {
    state.reviewCount = payload;
  }
};

// actions
const actions = {
  fetchProductReviews: ({commit, state, getters, rootState, rootGetters}, payload) => {
    return new Promise((resolve, reject) => {
      let product = getters.singleProductDetails;
      let martId = rootGetters.activeMartInfo.id;
      // console.log('getProductReviews', product)
      let page = payload.page;
      if (!page) {
        page = 0;
      }
      let url = constants.functionsUrl + "/searchReview" + "?martId=" + martId + "&productId=" + product.id + "&page=" + payload.page + "&size=" + getters.getReviewPageSize;
      console.log('URL', url)
      axios.get(url)
      .then((response) => {
        // console.log('Response', response.data)
        var objectConstructor = ({}).constructor;
        if (response.data.constructor === objectConstructor) {
          let result = response.data;
          commit('setReviewsCount', result.count);
          commit('setProductReviews', result.reviews);
          resolve;
        }
      })
      .catch(err => {
        console.error(err);
        reject(err);
      })
    })
  },
  addProductReview: ({commit, state, getters, rootState, rootGetters, dispatch}, payload) => {
    return new Promise(async (resolve, reject) => {
      let martId = rootGetters.activeMartInfo.id
      let customerId = rootGetters.activeCustomer ? rootGetters.activeCustomer.id : 0;
      let customerName = rootGetters.activeCustomer ? rootGetters.activeCustomer.name : '';
      let productId = getters.singleProductDetails ? getters.singleProductDetails.id : 0;
      let rating = payload.rating;
      let desc = payload.desc;
      let email = payload.email;
      let title = payload.title;

      let review = {
        "martId": martId,
        "custId": customerId,
        "email": email,
        "custName": customerName,
        "title": title,
        "productId": productId,
        "desc": desc,
        "published": true,
        "rating": rating,
        "date": firebase.firestore.FieldValue.serverTimestamp()
      };
      let docRef = await db.collection('reviews').doc();
      // console.log('Add review', review);
      await docRef.set(review);
      resolve()
      dispatch('fetchProductReviews', {page: 0});
    })
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
