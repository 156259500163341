<template>
  <transition name="fade">
    <div v-if="open" class="view-wrapper">
      <div class="loader-container">
        <div class="card">
          <div class="hero-xy">
            <div class="lds-roller" style="display: block; margin: auto;">
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
            <div v-if="config.message">
              <div style="height: 22px" />
              <div style="text-align: center; font-weight: normal; color: #000;">
                {{ config.message }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const defaultConfig = () => {
  return {
    message: ''
  }
}
export default {
  data () {
    return {
      open: false,
      config: Object.assign({}, defaultConfig())
    }
  },
  watch: {
    open (value) {
      if (!value) {
        const temp = defaultConfig()
        Object.keys(temp).forEach(key => {
          this.$set(this.config, key, temp[key])
        })
      }
    }
  },
  created () {
    this.$root.$on('b-loader/open', this.openDialog)
    this.$root.$on('b-loader/close', this.closeDialog)
    this.$root.$on('b-loader/message', this.setMessage)
  },
  methods: {
    openDialog (props) {
      if (this.open) {
        return
      }
      const { message } = props || {}
      
      if (message) {
        this.$set(this.config, 'message', message)
      }
      this.open = true
    },
    closeDialog () {
      this.open = false
    },
    setMessage (props) {
      const { message } = props || {}
      if (!message) {
        return
      }
      this.$set(this.config, 'message', message)
    }
  }
}
</script>

<style scoped>
  .hero-xy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .view-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.082);
    z-index: 1000;
  }
  .view-wrapper > .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .loader-container .card {
    position: relative;
    width: 100%;
    max-width: 320px;
    height: 150px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>
