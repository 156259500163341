<template>
  <v-navigation-drawer temporary :right="right" v-model="rightDrawer" fixed width="350">
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xs12>
          <h2 class="headline font-weight-regular">
            <v-icon color="brown" large left>shopping_cart</v-icon>SHOPPING CART
          </h2>
        </v-flex>
        <v-flex v-show="loader">
                <v-progress-linear indeterminate color="brown" height="3"></v-progress-linear>
            </v-flex>
        <v-flex xs12>
          <v-divider></v-divider>
        </v-flex>
        <v-flex xs12 v-for="product in products" :key="product.productTO.id">
          <v-layout row wrap>
            <!-- <v-flex hidden-md-and-down>
              <h3 class="body-1 font-weight-regular">{{ getCount(product.productTO.id) }}</h3>
            </v-flex> -->
            <v-flex xs12>
              <h3 class="body-1 font-weight-regular">{{ product.productTO.name}}</h3>
            </v-flex>
            <v-flex xs2 md2 lg2>
              <v-select flat dense solo :items="quantity" v-model="product.quantity" v-on:input="updateQuantity(product)"></v-select>
            </v-flex>
            <v-flex xs4 md4 lg4 pt-3>
              <h3 class="body-1 font-weight-medium">{{ getPrice(product.productTO)}}</h3>
            </v-flex>
            <v-flex xs4 md4 lg5 pt-3>
              <h3 class="body-1 font-weight-medium">T: {{ getTotalPrice(product.productTO, product.quantity) + ' ' + product.productTO.conversionDetailsTO.selectedCurrency}}</h3>
            </v-flex>
            <v-flex xs1 md1 lg1 mt-2>
              <h3><v-icon small @click="deleteProduct(product)">delete</v-icon></h3>
            </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <h3 class="font-weight-bold text-xs-right">Total: {{ totalDue() + ' ' + this.$store.getters.activeSelectedCurrency }}</h3>
        </v-flex>
            <v-flex xs12>
              <v-divider></v-divider>
            </v-flex>
        <v-flex xs12 md6>
          <v-btn class="brown" block dark to="/cart">Cart Page</v-btn>
        </v-flex>
        <v-flex xs12 md6>
          <v-btn :disabled="!enableCheckout" class="yellow" block to="/checkout">Checkout</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: [
    'drawer'
  ],
    data () {
        return {
            rightDrawer: this.$store.getters.getRightDrawer,
            right: true,
            quantity: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            loader: false
        }
    },
    computed: {
      ...mapGetters([
        'enableCheckout',
        'getRightDrawer',
        'getLoaderCartPage',
        'sortedCartProducts'
      ]),
      products: {
        get() {
          return this.sortedCartProducts
        }
      }
    },
    watch: {
      rightDrawer(val) {
        !val && this.$store.commit('setRightDrawer', false)
      },
      getRightDrawer(newVal) {
        if (newVal) {
          this.rightDrawer = this.$store.getters.getRightDrawer
        }
      },
      getLoaderCartPage(newValue) {
        if (newValue) {
          this.loader = true
        } else {
          this.loader = false
        }
      }
    },
    // mounted() {
      // this.getCart() 
        // this.$store.watch (
        //     (state, getters) => getters.getRightDrawer,
        //     (newValue, oldValue) => {
        //         // console.log(`Product details ${newValue}`)
        //         // console.log('Product Data', newValue)
        //         if(newValue) {
        //             this.rightDrawer = this.$store.getters.getRightDrawer
        //         }
        //     }
        // ),
        // this.$store.watch (
        //     (state, getters) => getters.activeCart,
        //     (newValue, oldValue) => {
        //         // console.log(`Product details ${newValue}`)
        //         // console.log('Product Data', newValue)
        //         if(newValue) {
        //             this.getCart()
        //         }
        //     }
        // ),
        // this.$store.watch (
        //     (state, getters) => getters.getLoaderRightDrawer,
        //     (newValue, oldValue) => {
        //       console.log('getLoaderRightDrawer watch from cart dialog', newValue)
        //             this.loader = newValue
        //     }
        // )
        // this.$store.watch (
        //     (state, getters) => getters.getLoaderCartPage,
        //     (newValue, oldValue) => {
        //         // console.log(`Product details ${newValue}`)
        //         // console.log('Cart Page Loader', newValue)
        //         if(newValue) {
        //             this.loader = true
        //         } else {
        //             this.loader = false
        //         }
        //     }
        // )
    // },
    // created() {
    //   // this.getCart()
    // },
    methods: {
      // getCart() {
      //   // this.loader = true
      //   if(this.$store.getters.activeCart.cartTO != null) {
      //     this.products = []
      //     let cart = this.$store.getters.activeCart
      //     let products = cart.cartTO.products
      //     // console.log('Products', cart.cartTO.products)
      //     products.forEach(product => {
      //       // console.log('Product', product)
      //       this.products.push(product)
      //       this.loader = false
      //       // this.$store.commit('setLoaderRightDrawer', false)
      //       // console.log('Products Data Array', this.products)
      //     })
      //   } else {
      //     // this.$store.dispatch('getCart');
      //     console.log('Temp User with empty cart')
      //   }
      // },
      getPrice(price) {
        // console.log('Object Sent for Price in cart', price)
            let conversionDetails = price.conversionDetailsTO
            if(conversionDetails) {
                let cost = (price.priceTO.price + price.priceTO.tax) * conversionDetails.conversion
                let priceLabel = Math.round(cost) + ' ' + conversionDetails.selectedCurrency
                // console.log('Price Cart', priceLabel)
                return priceLabel
            }
      },
      getTotalPrice(productTO, quantity) {
        // console.log('Object Sent for Price in cart', productTO)
            let conversionDetails = productTO.conversionDetailsTO
            if(conversionDetails) {
                let cost = (productTO.priceTO.price + productTO.priceTO.tax) * conversionDetails.conversion
                let totalCost = Math.round(cost) * quantity
                // let priceLabel = totalCost + ' ' + conversionDetails.selectedCurrency
                // console.log('Price Cart', priceLabel)           
                return totalCost
            }
      },
      getCount(id) {
        let num = 1
        // for(var i = 0; i < id; i++) {
        //   num++
        // }
        console.log('Num', num)
      },
      updateQuantity(product) {
        // this.$store.commit('setLoaderRightDrawer', true)
        console.log("Update Quantity from Cart", product)
        let operation = "QUANTITY_CHANGE_CART"
        this.$store.dispatch('cartUpdate', {"product": product, "operation": operation})
      },
      deleteProduct (product) {
        // this.$store.commit('setLoaderRightDrawer', true)
        console.log('Delete', product)
        let operation = "REMOVE_FROM_CART"
        this.$store.dispatch('cartUpdate', {"product": product, "operation": operation})
      },
      totalDue() {
        // console.log('Products', this.products)
        let grandTotal = 0
        if (this.products) {
          this.products.forEach(product => {
            grandTotal = grandTotal + this.getTotalPrice(product.productTO, product.quantity)
          })
        }
        return grandTotal
      }
    }
}
</script>