// Prod or Dev
var isProd = false

//--------------- Server urls -------------------
var Prod = "https://shopadmin.punamflutes.com";
// var Prod = "https://l3mart-live.appspot.com";
// var Prod = "https://task-227-6-dot-l3mart-live.appspot.com";
// var imsV1Prod = "https://task-383-dot-rnlgpc.appspot.com";
// var Dev = "https://l3-mart.appspot.com";
// var Dev = "http://localhost:8080";
var Dev = "https://sellstrong-dev-server.lounge3.com"; // "http://localhost:8888";

function url() {
    return isProd ? Prod : Dev;
}
var prodDomain = "shop.punamflutes.com";
// var devDomain = "l3-mart.appspot.com"; // "localhost";
var devDomain = "l3-mart.appspot.com"; // "localhost";

function getDomain() {
    return isProd ? prodDomain : devDomain;
}

 //--------------- Cloud Functions Urls ----------------
var functionsProd = "https://us-central1-sellstrong-prod.cloudfunctions.net"
var functionsDev = "https://us-central1-sellstrong-dev.cloudfunctions.net"
// var functionsDev = "http://localhost:5001/sellstrong-dev/us-central1"

function getFunctionsUrl() {
    return isProd ? functionsProd : functionsDev;
}

export default {
    url: url(),
    domainUrl: getDomain(),
    functionsUrl: getFunctionsUrl(),
    loginCookieValid: 6, // Days
    loginCookieName: "l3mart-userId" // Cookie to save Client copy of logged in customer id.
}

// deployment commands
// make constants isProd = false
// for dev - firebase deploy -P Dev --except functions