import axios from 'axios'
import constants from '~/components/constants'

export default function ({
    store,
    redirect,
    route
}) 

{
    if (!store.state.martInfo) {
        // console.log('Mart not initiated')
        // Check IMSv1session
        return getMartInfo(store)
        // }
    } else {

    }
}
// get Mart and pages
async function getMartInfo(store) {
    let martUrl = constants.url + '/api/l3mart?domain=' + constants.domainUrl
    try {
        const response = await axios.get(martUrl, { withCredentials: true });
        if (response.data) {
            let martInfo = response.data;
            // console.log('Mart Info', martInfo)
            store.commit('setMartInfo', martInfo.mart);
            store.commit('setPagesData', martInfo.pages);
            store.commit('setSelectedCurrency', martInfo.mart.userDefaultCurrency);
            store.commit('setMartCurrencies', martInfo.mart.currencies);
            // console.log('MART Info Store :', store.getters.activeMartInfo);
            // return getMartPages(store)
            let martId = martInfo.mart.id
            // if (!store.state.customer) {
                // console.log('User')
                return checkLogin(store, martId)
            // }
        } else {
                         
        }
    } catch (error) {
        console.log('Error:', error);
    }
}
// check login
async function checkLogin(store, martId) {
    // let martId = store.state.martInfo.mart.id
    let checkLoginUrl = constants.url + '/api/customer/check-login?martId=' + martId

    // Add clients copy of customerId from cookie to the request url.
    var userId = await store.dispatch('getCookie', constants.loginCookieName);
    if (userId) {
      checkLoginUrl = checkLoginUrl + "&customerId=" + userId;
    }

    try {
        const response = await axios.get(checkLoginUrl, { withCredentials: true }); // With creds so that server session is maintained with this browser.
        if (response.data) {
          // Set userId cookie
          let data = {};
          data.cname = constants.loginCookieName;
          data.cvalue = response.data.id;
          data.exdays = constants.loginCookieValid;
          store.dispatch("setCookie", data);
          let loginUser = response.data;
          store.commit('setCustomer', loginUser);           
        }
    } catch (error) {
        console.log('Error:', error);
    }
    // calling getCart store action to setCart in store
    store.dispatch('getCart');
}
// async function getMartInfo(store) {
//     let martUrl = constants.url + '/api/mart?domain=' + 'shop.punamflutes.com'
//     try {
//         const response = await axios.get(martUrl);
//         if (response.data) {
//             let martInfo = response.data;
//             // console.log('getEmpFrmServ():: Employee', employee);
//             store.commit('setMartInfo', martInfo);
//             console.log('MART Info Store :', store.getters.activeMartInfo);
//             return getMartPages(store)
//         } else {
                         
//         }
//     } catch (error) {
//         console.log('Error:', error);
//     }
// }
// async function getMartPages(store) {
//     let martId = store.getters.activeMartInfo.id
//     let pageUrl = constants.url + '/api/page?martId=' + martId
//     try {
//         const response = await axios.get(pageUrl);
//         if (response.data) {
//             let pageInfo = response.data;
//             // console.log('getEmpFrmServ():: Employee', employee);
//             // console.log('Pages', response.data)
//             store.commit('setPagesData', pageInfo);
//             console.log('PAGES Data Store :', store.getters.activePagesData);
//         } else {
                         
//         }
//     } catch (error) {
//         console.log('Error:', error);
//     }
// }