import firebase from "firebase/app";
import 'firebase/firestore'
import firebaseConfig from './firebaseConfig'
  
firebase.initializeApp(firebaseConfig);

const settings = {
  timestampsInSnapshots: true
};
export const db = firebase.firestore();
db.settings(settings)

export default firebase