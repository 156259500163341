<template>
  <v-app>
    <v-navigation-drawer mini-variant.sync :clipped="clipped" v-model="drawer" fixed width="230"
      mobile-break-point="1264" app>
      <v-toolbar>
        <v-list>
          <v-list-tile>
            <!-- <v-list-tile-title class="title">
            User Name
          </v-list-tile-title> -->
            <v-list-tile-content>
              <v-list-tile-title>{{ customerName | truncate }}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action v-show="getLoginState">
              <v-btn small flat color="brown" @click="customerLogin">Login</v-btn>
            </v-list-tile-action>
            <v-list-tile-action v-show="getLogoutState">
              <v-btn small dark color="brown" :loading="getLogoutBtnLoader" @click="customerLogout">Logout</v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-divider></v-divider>
      <!-- <v-navigation-drawer
    stateless
    value="true"
    app
    width="230"
    > -->
      <!-- <v-list>
        <v-list-tile
          router
          :to="item.to"
          :key="i"
          v-for="(item, i) in items"
          exact
        >
          <v-list-tile-action>
            <v-icon v-html="item.icon"></v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title v-text="item.title"></v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list> -->
      <v-list dense class="pt-0">
        <v-list-tile router :to="link.to" :key="i" v-for="(link, i) in links" exact>
          <v-list-tile-action>
            <v-icon v-text="link.icon"></v-icon>
          </v-list-tile-action>
          <v-list-tile-title>
            <v-list-tile-title v-text="link.title"></v-list-tile-title>
          </v-list-tile-title>
        </v-list-tile>

        <v-list-group value="true">
          <template v-slot:activator>
            <v-list-tile :to="menu.to" :key="i" v-for="(menu, i) in customerMenu" exact>
              <v-list-tile-action>
                <v-icon v-text="menu.icon"></v-icon>
              </v-list-tile-action>
              <v-list-tile-title v-text="menu.title"></v-list-tile-title>
            </v-list-tile>
          </template>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar fixed app :clipped-left="clipped" color="brown darken-1" dark>
      <v-toolbar-side-icon @click="drawer = !drawer"></v-toolbar-side-icon>
      <!-- <v-btn
        icon
        @click.stop="miniVariant = !miniVariant"
      >
        <v-icon v-html="miniVariant ? 'chevron_right' : 'chevron_left'"></v-icon>
      </v-btn> -->
      <!-- <v-btn
        icon
        @click.stop="clipped = !clipped"
      >
        <v-icon>web</v-icon>
      </v-btn> -->
      <!-- <v-btn
        icon
        @click.stop="fixed = !fixed"
      >
        <v-icon>remove</v-icon>
      </v-btn> -->
      <v-toolbar-title v-text="title"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        flat
        solo-inverted
        append-icon="search"
        label="Search" 
        class="hidden-sm-and-down" 
        @click:append="search" 
        @keydown.enter="search"
        v-model="searchData"
      ></v-text-field>
      <v-spacer></v-spacer>
      <!-- <v-btn
        icon
        @click.stop="rightDrawer = !rightDrawer"
      >
        <v-icon>menu</v-icon>
      </v-btn> -->
      <v-flex xs4 md1>
        <currency-convertor design3 type="currency"></currency-convertor>
      </v-flex>
    </v-toolbar>
    <v-content>
      <!-- <v-container> -->
      <nuxt />
      <!-- </v-container> -->
    </v-content>
    <cart></cart>
    <!-- <v-navigation-drawer temporary :right="right" v-model="rightDrawer" fixed>
      <v-list>
        <v-list-tile @click.native="right = !right">
          <v-list-tile-action>
            <v-icon light>compare_arrows</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>Shopping Cart</v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer> -->
    <v-footer :fixed="fixed" app>
      <span>&copy; 2018 <a target="_blank" style="text-decoration: none; color:brown;" href="https://sellstrong-site.web.app/">SellStrong</a></span>
    </v-footer>
    <!-- Cart icon floting at bottom right -->
    <v-btn nuxt @click="showRightDrawer" large fab right bottom fixed color="yellow">
      <v-icon dark>shopping_cart</v-icon>
    </v-btn>
    <login></login>
    <snack-bar></snack-bar>
  </v-app>
</template>

<script>
  import axios from 'axios'
  import constants from '~/components/constants'
  import CurrencyConvertor from '~/components/CurrencyConvertor'
  import Login from '~/components/Login'
  import Cart from '~/components/Cart'
  import SnackBar from '~/components/Notifications/SnackBar'
  import {mapGetters} from 'vuex'

  export default {

    components: {
      CurrencyConvertor,
      Login,
      Cart,
      SnackBar
    },
    data() {
      return {
        clipped: true,
        drawer: true,
        fixed: false,
        items: [{
            icon: 'apps',
            title: 'Home',
            to: '/'
          },
          {
            icon: 'people',
            title: 'Products',
            to: '/products'
          }
        ],
        links: [],
        customerMenu: [],
        miniVariant: true,
        title: this.$store.getters.activeMartInfo.name,
        martId: '',
        pageId: [],
        url: constants.url,
        customerName: '',
        searchData: null
      }
    },
    filters: {
      truncate: function (value) {
        if (value.length > 14) {
          value = value.substring(0, 10) + '...';
        }
        return value
      }
    },
    computed: {
      ...mapGetters(['getLoginState','getLogoutState', 'getLogoutBtnLoader', 'activeCustomer'])
    },
    watch: {
      activeCustomer(val) {
        if (val) {
          // Redirect to Home page if temp user.
          this.getMartPages(val.temp)
        }
      }
    },
    created: function () {
      this.getMartPages(false)
    },
    methods: {
      getMartPages(mandatoryRedirect) {
        if (this.$store.getters.activePagesData) {
          this.links = []
          let pagesData = this.$store.getters.activePagesData
          pagesData.forEach(element => {
            if (element.publish) {
              let menu = {
                icon: 'apps',
                title: element.title,
                to: "/pages/" + element.id
              }
              this.links.push(menu)

              // Redirect to default/Home page if no page id found in URL.
              if ((this.$route.path == '/' || mandatoryRedirect) && (element.defaultPage)) {
                this.$router.push('/pages/' + element.id)
              }
            }
          })
              this.addCustomerMenu()
        }
      },
      addCustomerMenu() {
        let customerLoginStatus = this.$store.getters.activeCustomer
        this.customerName = 'GUEST'
        this.$store.commit('setLoginState', true)
        // this.login = true
        if(!customerLoginStatus.temp) {
          this.$store.commit('setLoginState', false)
          this.$store.commit('setLogoutState', true)
          // this.logout = true
          let menu = [
            {
            icon: 'dashboard',
            title: 'My Orders',
            to: '/orders'
          },
          {
            icon: 'people',
            title: 'My Profile',
            to: '/profile'
          }]
          this.customerMenu = menu
          this.customerName = customerLoginStatus.name ? customerLoginStatus.name : 'Welcome!'
        } else {
          this.customerMenu = null
        }
      },
      customerLogin() {
        this.$store.commit('setLoginDialog', true)
      },
      customerLogout() {
        this.$store.dispatch('userLogout')
      },
      showRightDrawer() {
        this.$store.commit('setRightDrawer', true)
        this.$store.commit('setLoaderCartPage', true) 
        this.$store.dispatch('getCart')
      },
      search() {
        // console.log('Search Data', this.searchData)
        this.$router.push('/searchResults?searchText=' + this.searchData)
      }
    }
  }
</script>
