<template>
  <div>
    <!-- register components -->
    <toast />
    <blocking-loader />
    <!-- top banner position -->
    <div ref="pageWrapper" class="page-wrapper">
      <div>
        <div class="top-notice text-white bg-dark">
          <div class="container text-center">
            <!-- <h5 class="ls-n-10 mb-0">
              Get 10% extra OFF on Summer Sale - Use
              <b>PUNAMSUMMER</b> coupon -
              <NuxtLink to="/shop">Shop Now!</NuxtLink>
            </h5> -->
            <h5 class="ls-n-10 mb-0">
              Due to <b>Lockdown</b> measures announced by various states, delivery timelines may be impacted.
              <NuxtLink to="/blog/delivery-delays-due-to-lockdown">Read more</NuxtLink>
            </h5>
            <button title="Close (Esc)" type="button" class="mfp-close">×</button>
          </div>
          <!-- End .container -->
        </div>
        <!-- End .top-notice -->
      </div>
      <!--  -->
      <header class="header header-transparent">
        <div class="header-middle">
          <div class="container">
            <div class="header-left">
              <!-- <a href="/" class="logo">
              <img src="/website/assets/images/logo9.png" alt="Punam Flutes" />
            </a> -->
              <NuxtLink to="/" class="logo">
                <img src="/website/assets/images/logo6.png" alt="Punam Flutes" class="light-logo" />
                <img src="/website/assets/images/logo_light.png" alt="Punam Flutes" class="dark-logo" />
              </NuxtLink>

              <nav class="main-nav font2">
                <ul class="menu">
                  <li>
                    <!-- <a href="/test">Home</a> -->
                    <NuxtLink to="/">Home</NuxtLink>
                  </li>
                  <li>
                    <NuxtLink to="/about-us">About</NuxtLink>
                    <!-- <div class="megamenu megamenu-fixed-width megamenu-3cols">
                        <div class="row">
                            <div class="col-lg-4">
                                <a href="#" class="nolink">VARIATION 1</a>
                                <ul class="submenu">
									<li><a href="category.html">Fullwidth Banner</a></li>
									<li><a href="category-banner-boxed-slider.html">Boxed Slider Banner</a></li>
									<li><a href="category-banner-boxed-image.html">Boxed Image Banner</a></li>
									<li><a href="category.html">Left Sidebar</a></li>
									<li><a href="category-sidebar-right.html">Right Sidebar</a></li>
									<li><a href="category-flex-grid.html">Product Flex Grid</a></li>
									<li><a href="category-horizontal-filter1.html">Horizontal Filter1</a></li>
									<li><a href="category-horizontal-filter2.html">Horizontal Filter2</a></li>
								</ul>
                            </div>
                        </div>
                    </div> -->
                  </li>
                  <!-- This last Li tag will be static URL of internal shop folder..  -->
                  <li>
                    <!-- <a href="/shop">Shop</a> -->
                    <NuxtLink to="/shop">Shop</NuxtLink>
                  </li>
                  <li>
                    <NuxtLink to="/contact">Contact</NuxtLink>
                  </li>
                </ul>
              </nav>
            </div>
            <!-- right icon -->
            <div class="header-right">
              <div class="header-right header-dropdowns">
                <!-- <div class="header-dropdown">
                  <a href="#" class="pl-0"><img src="/website_internal/assets/images/flags/en.png" alt="England flag" />Eng</a>
                  <div class="header-menu">
                    <ul>
                      <li>
                        <a href="#"><img src="/website_internal/assets/images/flags/en.png" alt="England flag" />Eng</a>
                      </li>
                      <li>
                        <a href="#"><img src="/website_internal/assets/images/flags/fr.png" alt="France flag" />Frh</a>
                      </li>
                    </ul>
                  </div>
                </div> -->
                <!-- End .header-dropdown -->
                <div class="header-dropdown ml-4">
                  <a href="#">USD</a>
                  <div class="header-menu">
                    <ul>
                      <li><a href="#">EUR</a></li>
                      <li><a href="#">USD</a></li>
                    </ul>
                  </div>
                </div>
                <!-- End .header-menu -->

                <span class="separator"></span>
                <!-- <div class="header-right"> -->
                <button id="id-mobile-menu-toggler" class="mobile-menu-toggler" type="button" @click="onClickPancake">
                  <i class="icon-menu"></i>
                </button>

                <!-- <a href="/login.html" class="header-icon login-link"
                ><i class="icon-user-2"></i
              ></a> -->
                <LoginPopup />
                <!-- Icon wishlist or like -->
                <!-- <a href="#" class="header-icon"><i class="icon-wishlist-2"></i></a> -->

                <div class="header-search header-search-popup header-search-category d-none d-sm-block">
                  <a href="#" class="search-toggle" role="button"><i class="icon-magnifier"></i></a>
                  <form action="#" method="get">
                    <div class="header-search-wrapper">
                      <input type="search" class="form-control" name="q" id="q" placeholder="I'm searching for..." required="" />
                      <div class="select-custom">
                        <select id="cat" name="cat">
                          <option value="">All Categories</option>
                          <option value="4">Fashion</option>
                          <option value="12">- Women</option>
                          <option value="13">- Men</option>
                          <option value="66">- Jewellery</option>
                          <option value="67">- Kids Fashion</option>
                          <option value="5">Electronics</option>
                          <option value="21">- Smart TVs</option>
                          <option value="22">- Cameras</option>
                          <option value="63">- Games</option>
                          <option value="7">Home &amp; Garden</option>
                          <option value="11">Motors</option>
                          <option value="31">- Cars and Trucks</option>
                          <option value="32">
                            - Motorcycles &amp; Powersports
                          </option>
                          <option value="33">- Parts &amp; Accessories</option>
                          <option value="34">- Boats</option>
                          <option value="57">- Auto Tools &amp; Supplies</option>
                        </select>
                      </div>
                      <!-- End .select-custom -->
                      <button class="btn bg-dark icon-search-3" type="submit"></button>
                    </div>
                    <!-- End .header-search-wrapper -->
                  </form>
                </div>

                <DropdownCart />
                <!-- End .dropdown -->
              </div>
              <!-- End .header-right -->
            </div>
            <!-- body container  -->
          </div>
        </div>
      </header>
      <nuxt />
    </div>
    <footer class="footer">
      <div class="footer-top">
        <div class="container top-border d-flex align-items-center justify-content-between flex-wrap">
          <div class="footer-left widget-newsletter d-md-flex align-items-center">
            <div class="widget-newsletter-info">
              <h5 class="widget-newsletter-title text-uppercase m-b-1">
                subscribe newsletter
              </h5>
              <p class="widget-newsletter-content mb-0">
                Get all the latest information on Events, Sales and Offers.
              </p>
            </div>
            <form action="#">
              <div class="footer-submit-wrapper d-flex">
                <input type="email" class="form-control" placeholder="Email address..." size="40" required />
                <button type="submit" class="btn btn-dark btn-sm">
                  Subscribe
                </button>
              </div>
            </form>
          </div>
          <div class="footer-right">
            <div class="social-icons">
              <a href="#" class="social-icon social-facebook icon-facebook" target="_blank"></a>
              <a href="#" class="social-icon social-twitter icon-twitter" target="_blank"></a>
              <a href="#" class="social-icon social-instagram icon-instagram" target="_blank"></a>
            </div>
            <!-- End .social-icons -->
          </div>
        </div>
      </div>

      <div class="footer-middle">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-xl-4">
              <div class="widget">
                <h4 class="widget-title">Contact Info</h4>

                <div class="row">
                  <div class="col-sm-6">
                    <div class="contact-widget">
                      <h4 class="widget-title">ADDRESS</h4>
                      <a href="#">A 58, Jawahar Park, Deoli road, Khanpur, New Delhi 110080, India</a>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="contact-widget email">
                      <h4 class="widget-title">EMAIL</h4>
                      <a href="mailto:info@flute.in">info@flute.in</a>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="contact-widget">
                      <h4 class="widget-title">PHONE</h4>
                      <a href="#">+91 850-592-2922 (9AM to 6PM IST)</a>
                      <a href="#">+91 892-003-8458 (9AM to 6PM IST)</a>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="contact-widget">
                      <h4 class="widget-title">WORKING DAYS/HOURS</h4>
                      <a href="#">Mon - Sun / 9:00 AM - 6:00 PM</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3 col-xl-4">
              <div class="widget">
                <h4 class="widget-title">My Account</h4>
                <ul class="links link-parts row">
                  <div class="link-part col-xl-4">
                    <li><NuxtLink to="/about-us">About Us</NuxtLink></li>
                    <li><NuxtLink to="contact">Contact Us</NuxtLink></li>
                    <li><a href="my-account.html">My Account</a></li>
                  </div>
                  <div class="link-part col-xl-8">
                    <li><NuxtLink to="/shop/orders">Orders History</NuxtLink></li>
                    <li><a href="#">Advanced Search</a></li>
                  </div>
                </ul>
              </div>
              <!-- End .widget -->
            </div>
            <div class="col-sm-6 col-lg-3 col-xl-4">
              <div class="widget">
                <h4 class="widget-title">Important Links</h4>
                <ul class="links link-parts row">
                  <div class="link-part col-xl-6">
                    <li><NuxtLink to="/terms-conditions">Terms & Conditions</NuxtLink></li>
                    <li><NuxtLink to="/return-policy">Return Policy</NuxtLink></li>
                    <li><NuxtLink to="/refund-policy">Refund Policy</NuxtLink></li>
                    <!-- <li><a href="#">Beware of Duplicates</a></li> -->
                  </div>
                  <div class="link-part col-xl-6">
                    <li><NuxtLink to="/payment-methods">Payment Methods</NuxtLink></li>
                    <li><NuxtLink to="/discounts">Discounts</NuxtLink></li>
                    <li><a href="#">Packaging/Shipping</a></li>
                  </div>
                </ul>
              </div>
              <!-- End .widget -->
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container top-border d-flex align-items-center justify-content-between flex-wrap">
          <p class="footer-copyright mb-0 py-3 pr-3">
            <a href="https://sellstrong-site.web.app/" style="color: #A52A2A" target="_blank">SellStrong</a>
            &copy; 2021. All Rights Reserved
          </p>
          <img class="py-3" src="/website/assets/images/payments.png" alt="payment image" />
        </div>
      </div>
    </footer>
    <div class="mobile-menu-overlay"></div>
    <div class="mobile-menu-container">
      <div class="mobile-menu-wrapper">
        <span class="mobile-menu-close"><i class="icon-compare-link"></i></span>
        <nav class="mobile-nav">
          <ul class="mobile-menu">
            <li class="active">
              <NuxtLink to="/">Home</NuxtLink>
            </li>
            <li>
              <NuxtLink to="/shop">Shop</NuxtLink>
            </li>
            <li>
              <NuxtLink to="/about-us">About</NuxtLink>
            </li>
            <li>
              <NuxtLink to="/shop/orders">My Orders</NuxtLink>
            </li>
            <li>
              <NuxtLink to="/shop/cart">My Cart</NuxtLink>
            </li>
            <li>
              <NuxtLink to="/contact">Contact</NuxtLink>
            </li>
            <!-- <li>
              <NuxtLink to="/blog">Blog</NuxtLink>
            </li> -->
            <!-- <li>
                <a href="https://sellstrong-site.web.app/" target="_blank">SellStrong</a></li> -->
          </ul>
        </nav>
        <!-- End .mobile-nav -->

        <div class="social-icons">
          <a href="#" class="social-icon" target="_blank"><i class="icon-facebook"></i></a>
          <a href="#" class="social-icon" target="_blank"><i class="icon-twitter"></i></a>
          <a href="#" class="social-icon" target="_blank"><i class="icon-instagram"></i></a>
        </div>
        <!-- End .social-icons -->
      </div>
      <!-- End .mobile-menu-wrapper -->
    </div>

    <a id="scroll-top" href="#top" title="Top" role="button"><i class="icon-angle-up"></i></a>
  </div>
</template>

<script>
import LoginPopup from "~/components/LoginPopup";
import DropdownCart from "~/components/Checkout/DropdownCart";
import MobMenu from "~/components/MobMenu";
import Toast from '@/components/Toast'
import BlockingLoader from '@/components/BlockingLoader'

export default {
  components: { LoginPopup, DropdownCart, MobMenu, Toast, BlockingLoader },
  mounted: function() {
    // document.body.classList.add('btn-rounded')
    // document.body.classList.add('scroll-assist')
    // this.loadScript('/website/assets/js/main.min.js')
    // this.loadScript('/website/assets/js/jquery.min.js')
    // this.loadScript("/website/assets/js/bootstrap.bundle.min.js");
    // this.loadScript("/website/assets/js/plugins.min.js");
    // this.loadScript('/website/js/bootstrap.min.js')
    // this.loadScript('/website/js/flexslider.min.js')
    // this.loadScript('/website/js/twitterfetcher.min.js')
    // this.loadScript('/website/js/parallax.js')
    // this.loadScript('/website/js/scripts.js')
    this.$nextTick(() => {
      window.$('.mobile-menu-overlay, .mobile-menu-close').on('click', function (e) {
				window.$('body').removeClass('mmenu-active');
				window.$('.menu-toggler').removeClass('active');
				e.preventDefault();
			});
      document.addEventListener('click', (event) => {
        if (
          !isDescendant(event.target, 'id-mobile-menu-container') &&
          !isDescendant(event.target, 'id-mobile-menu-toggler')
        ) {
          this.toggleMainNav = false
        }
      })
    })
  },
  methods: {
    onClickPancake (e) {
      window.$('body').addClass('mmenu-active');
      window.$('.menu-toggler').addClass('active');
      e.preventDefault();
    },
    loadScript(src) {
      let tag = document.createElement("script");
      tag.src = src;
      document.body.appendChild(tag);
      //   WebFontConfig = {
      // 		google: { families: [ 'Open+Sans:300,400,600,700,800','Poppins:300,400,500,600,700' ] }
      // 	};
      // 	(function(d) {
      // 		var wf = d.createElement('script'), s = d.scripts[0];
      // 		wf.src = src
      // 		wf.async = true;
      // 		s.parentNode.insertBefore(wf, s);
      // 	})(document);
    },
  },
  head() {
    return {
      title: "Punam Flutes - Online Flute",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Punam Flutes, Indian Bamboo Flutes (Bansuri)",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, shrink-to-fit=no",
        },
      ],
      link: [
        {
          rel: "stylesheet",
          href: "/website/assets/css/bootstrap.min.css",
          type: "text/css",
          media: "all",
        },
        {
          rel: "stylesheet",
          href: "/website/assets/css/style.min.css",
          type: "text/css",
          media: "all",
        },
        {
          rel: "stylesheet",
          href: "/website/assets/vendor/fontawesome-free/css/all.min.css",
          type: "text/css",
          media: "all",
        },
        {
          rel: "stylesheet",
          href: "/website/assets/css/custom.css",
          type: "text/css",
          media: "all",
        },
        //  { rel: 'stylesheet', href: '/website/css/flexslider.css', type: 'text/css', media: 'all' },
        // //  { rel: 'stylesheet', href: '/website/css/lightbox.min.css', type: 'text/css', media: 'all' },
        // //  { rel: 'stylesheet', href: '/website/css/ytplayer.css', type: 'text/css', media: 'all' },
        //  { rel: 'stylesheet', href: '/website/css/theme-nature.css', type: 'text/css', media: 'all' },
        // //  { rel: 'stylesheet', href: '/website/css/theme.css', type: 'text/css', media: 'all' },
        //  { rel: 'stylesheet', href: '/website/css/custom.css', type: 'text/css', media: 'all' },
        //  { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Lato:300,400%7CRaleway:100,400,300,500,600,700%7COpen+Sans:400,500,600', type: 'text/css'},
        //  { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Open+Sans:100,300,400,600,700', type: 'text/css'},
        //  { rel: 'stylesheet', href: '/website/css/font-opensans.css', type: 'text/css', media: 'all' }
      ],
      script: [
        // { src: "/website/assets/js/webfont.js" },
        // { src: "/website/assets/js/jquery.min.js" },
        // { src: "/website/assets/js/main.js" },
        // { src: "/website/assets/js/bootstrap.bundle.min.js" },
        // { src: "/website/assets/js/plugins.min.js" },
        // { src: '/website/js/bootstrap.min.js'},
        // { src: '/website/js/flexslider.min.js'},
        // { src: '/website/js/twitterfetcher.min.js'},
        // { src: '/website/js/scripts.js'},
        // { src: '/__/firebase/6.3.4/firebase-app.js', type: "text/javascript"},
        // { src: '/__/firebase/init.js', type: "text/javascript"}
      ],
    };
  },
};
const isDescendant = (el, parentId) => {
  let isChild = false

  if (el.id === parentId) {
    isChild = true
  }

  // eslint-disable-next-line no-cond-assign
  while (el = el.parentNode) {
    if (el.id === parentId) {
      isChild = true
    }
  }

  return isChild
}
</script>

<style scoped>
#more {
  cursor: pointer;
}
.main-nav .menu > li:hover > a, .main-nav .menu li.show > a, .main-nav .menu
li.active > a {
  color: #a8a4a4;
}
</style>
