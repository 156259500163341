<template>
    <v-snackbar v-model="getSnackBar.state" :bottom="y === 'bottom'" :left="x === 'left'"
        :multi-line="mode === 'multi-line'" :right="x === 'right'" :timeout="timeout" :top="y === 'top'"
        :vertical="mode === 'vertical'">
        {{ getSnackBar.message }}
        <v-btn color="pink" flat @click="snackbar = false">
            Close
        </v-btn>
    </v-snackbar>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    data() {
        return {
            y: 'top',
            x: null,
            mode: '',
            timeout: 3000,
        }
    },
    computed: {
        ...mapGetters(['getSnackBar'])
    }
}
</script>