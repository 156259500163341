import GhostContentAPI from "@tryghost/content-api";

// state
const state = {
    posts: [],
    pages: []
}

const getters = {
    activePosts: (state) => {
        return state.posts
    },
    activePages: (state) => {
        return state.pages
    }
}

const mutations = {
    setPosts: (state, payload) => {
        // console.log('payload', payload)
        state.posts.push(payload)
    },
    setPages: (state, payload) => {
        // console.log('payload pages', payload)
        state.pages.push(payload)
    }
}

const actions = {
    getPosts: ({ commit, state }, payload) => {
        state.posts = []
        let api = new GhostContentAPI({
            url: "https://blog.punamflutes.com",
            key: "2fa1ceb477c64ea05f4dd9ff40",
            version: "v3",
        })
        api.posts.browse({ limit: 'all', include: "tags,authors" })
            .then((posts) => {
                posts.forEach((post) => {
                    //   console.log(post)
                    commit('setPosts', post)
                })
            })
            .catch((err) => {
                console.error(err);
            })
    },
    getPages: ({ commit }, payload) => {
        return new Promise((resolve, reject) => {
            let api = new GhostContentAPI({
                url: "https://blog.punamflutes.com",
                key: "2fa1ceb477c64ea05f4dd9ff40",
                version: "v3",
            })
            api.pages.browse({ limit: 'all', include: "tags,authors" })
                .then((pages) => {
                    pages.forEach((page) => {
                        //   console.log(post)
                        commit('setPages', page)
                        resolve(page)
                    })
                }, error => {
                    console.log('error')
                    reject(error)
                })
                .catch((err) => {
                    console.log('catch')
                    console.error(err)
                })
        }
        )
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
