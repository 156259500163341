import axios from "axios";
import constants from "~/components/constants";

export default function({ store, redirect, route }) {
  if (store.state.martInfo) {
    // console.log('Mart not initiated', store.state.martInfo)
    // Check IMSv1session
    return getSingleProductDetails(store, route);
    // }
  } else {
  }
}

async function getSingleProductDetails(store, route) {
  // TODO get rest url for image for product details..
  let productId = route.params.id;
  //console.log("Product Id", productId);
  let productUrl = constants.url + "/api/product?slug=" + productId + "&selectedCurrency=" + store.state.selectedCurrency + "&martId=" + store.state.martInfo.id
  // console.log('Product URL', productUrl)
  try {
    const response = await axios.get(productUrl, { withCredentials: true });
    // console.log('Response data[0]', response.data)
    if (response.data && response.data[0] && response.data[0].published == true) {
      // console.log('Image Response', response.data[0])
      store.commit("setSingleProductDetails", response.data[0]);
      return getImagePath(store, route, response.data[0].id);
    } else {
    }
  } catch (error) {
    console.log('Error 1', error)
  }
}

async function getImagePath(store, route, id) {
  // TODO get rest url for image for product details..
  let productId = id;
  // console.log("Product Id", productId);
  let imageDataUrl = constants.url + "/api/image?productId=" + productId;
  // console.log("ImageDataURl", imageDataUrl);
  // let parent = this;
  try {
    const response = await axios.get(imageDataUrl, { withCredentials: true });
    // console.log('Response', response)
    if (response.data) {
      // console.log('Image Response', response.data)
      store.commit("setProductImageUrl", response.data);
    } else {
    }
  } catch (error) {}
}