var currencies = {
    "INR": {
        "symbol": "₹",
        "displayName": "INR",
        "code": "INR"
    },
    "USD": {
        "symbol": "$",
        "displayName": "USD",
        "code": "USD"
    },
    "EURO": {
        "symbol": "€",
        "displayName": "EURO",
        "code": "EUR"
    },
    "GBP": {
        "symbol": "£",
        "displayName": "GBP",
        "code": "GBP"
    },
    "YEN": {
        "symbol": "¥",
        "displayName": "YEN",
        "code": "JPY"
    },
    "CAD": {
        "symbol": "C$",
        "displayName": "CAD",
        "code": "CAD"
    },
    "HKD": {
        "symbol": "HK$",
        "displayName": "Hong Kong dollar",
        "code": "HKD"
    },
    "RUB": {
        "symbol": "₽",
        "displayName": "Ruble",
        "code": "RUB"
    },
    "AUD": {
        "symbol": "AU$",
        "displayName": "AUD",
        "code": "AUD"
    },
    "RMB": {
        "symbol": "¥",
        "displayName": "CNY",
        "code": "CNY"
    },
    // Bangladeshi Taka
    "BDT": {
        "symbol": "৳",
        "displayName": "Taka",
        "code": "BDT"
    },
    // Emirati Dhiram
    "AED": {
        "symbol": "د.إ",
        "displayName": "Dhs",
        "code": "AED"
    },
    // 
    "PKR": {
        "symbol": "PKR",
        "displayName": "Pakistani Rupee",
        "code": "PKR"
    }
}

export default {
    currencies: currencies,
}