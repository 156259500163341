import constants from '~/components/constants'
import axios from 'axios'

const state = {
    searchResults: null,
    searchPageLoader: false
}

const getters = {
    activeSearchResults: (state) => {
        return state.searchResults
    },
    activeSearchPageLoader: (state) => {
        return state.searchPageLoader
    },
}

const mutations = {
    setSearchResults(state, payload) {
        // console.log('Set Login Dialog', payload)
        state.searchResults = payload
    },
    setSearchPageLoader(state, payload) {
        // console.log('Set Login Dialog', payload)
        state.searchPageLoader = payload
    }
}

const actions = {
    search({commit, getters, rootGetters}, searchData) {
        commit('setSearchPageLoader', true)
        // /api/product?martId=<martId>&categoryId=<categoryId>&page=<page-number (0-n..)>&size=<size (1-n..)>&searchText
        let martId = getters.activeMartInfo
        let selectedCurrency = rootGetters.activeSelectedCurrency
        // console.log('SearchData Store', searchData)
        let searchUrl = constants.url + '/api/product?martId=' + martId.id + '&searchText=' + searchData.searchText + '&selectedCurrency=' + selectedCurrency
        console.log('SearchUrl from store', searchUrl)
        axios.get(searchUrl, {withCredentials: true})
        .then(response => {
            let publishedProducts = []
            let data = response.data
            data.forEach(product => {
              if(product.published) {
                  publishedProducts.push(product)
              }  
            })
            console.log('Search results response', publishedProducts)
            commit('setSearchResults', publishedProducts)
            commit('setSearchPageLoader', false)
            // this.$router.push('/searchResults?searchText=' + searchData)
        })
    }
}

export default {
    // namespaced: true,
    state,
    getters,
    mutations, 
    actions
}