<template>
     <div class="dropdown cart-dropdown">
                <a href="#" class="dropdown-toggle dropdown-arrow" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                  <i class="icon-shopping-cart"></i>
                  <span class="cart-count badge-circle">{{products.length}}</span>
                </a>

                <div class="dropdown-menu">
                  <div class="dropdownmenu-wrapper">
                    <div class="dropdown-cart-header">
                      <span>{{products.length}} Items</span>

                      <!-- <a href="cart.html" class="float-right">View Cart</a> -->
                       <NuxtLink to="/shop/cart" class="float-right">View Cart</NuxtLink>
                    </div>
                    <!-- End .dropdown-cart-header -->

                    <div class="dropdown-cart-products" v-for="product in products" :key="product.id">
                      <div class="product">
                        <div class="product-details">
                          <h4 class="product-title">
                            <NuxtLink :to="`/shop/` + product.productTO.slug">{{product.productTO.name}}</NuxtLink>
                          </h4>

                          <span class="cart-product-info">
                            <span class="cart-product-qty">{{product.quantity}}</span>
                            x {{ toDisplayPrice(getPrice(product.productTO)) }}
                          </span>
                        </div>
                        <!-- End .product-details -->

                        <figure class="product-image-container">
                          <NuxtLink :to="`/shop/` + product.productTO.slug" class="product-image">
                            <img :src="getImagePath(product)" alt="product" width="80" height="80" />
                          </NuxtLink>
                          <a class="btn-remove icon-cancel" style="cursor: pointer;" title="Remove Product" @click="deleteProduct(product)"></a>
                        </figure>
                      </div>
                      <!-- End .product -->
                    </div>
                    <!-- End .cart-product -->

                    <div class="dropdown-cart-total">
                      <span>Total</span>

                      <span class="cart-total-price float-right">{{ toDisplayPrice(totalDue()) }}</span>
                    </div>
                    <!-- End .dropdown-cart-total -->

                    <div class="dropdown-cart-action">
                      <NuxtLink to="/shop/checkout" class="btn btn-primary btn-block">Checkout</NuxtLink>
                    </div>
                    <!-- End .dropdown-cart-total -->
                  </div>
                  <!-- End .dropdownmenu-wrapper -->
                </div>
                <!-- End .dropdown-menu -->
              </div>
</template>

<script>
import constants from '~/components/constants'
import { mapGetters } from 'vuex'
import { mapState } from 'vuex'

export default {
    data () {
        return {
            // products: [],
            // quantity: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        }
    },
    // mounted() {
    //     this.getCart()
    //     // this.$store.watch (
    //     //     (state, getters) => getters.activeCart,
    //     //     (newValue, oldValue) => {
    //     //         // console.log(`Product details ${newValue}`)
    //     //         // console.log('Product Data', newValue)
    //     //         if(newValue) {
    //     //             this.getCart()
    //     //         }
    //     //     }
    //     // )
    // },
    computed: {
        ...mapGetters([
            'sortedCartProducts',
            'selCurrDispName',
            'selCurrSymbol'
        ]),
        products: {
            get() {
                // console.log('Computed products', this.sortedCartProducts)
                return this.sortedCartProducts
            }
        }
    },
    methods: {
      getPrice(price) {
        // console.log('Object Sent for Price in cart', price)
            let conversionDetails = price.conversionDetailsTO
            if(conversionDetails) {
                let cost = (price.priceTO.price + price.priceTO.tax) * conversionDetails.conversion
                let priceLabel = Math.round(cost)
                // console.log('Price Cart', priceLabel)
                return priceLabel
            }
      },
        toDisplayPrice(price) {
            if (price) {
                return this.selCurrSymbol + price.toLocaleString()
            }
        },
        getImagePath(product) {
          return constants.url + '/download?gsObjectName=' + product.productTO.imagePath
      },
    //   getCart() {
    //     if(this.$store.getters.activeCart && this.$store.getters.activeCart.cartTO != null) {
    //         this.$store.state.checkout.orderData = []
    //       this.products = []
    //       let cart = this.$store.getters.activeCart
    //       let products = cart.cartTO.products
    //       // Sort products alphabetically.
    //       products = products.sort(function (a, b) {
    //         //   console.log('product', a, b)
    //           let name1 = a.productTO.name
    //           let name2 = b.productTO.name
    //           if (name1 < name2) {
    //               return -1;
    //           }
    //           if (name1 > name2) {
    //               return 1;
    //           }
    //           return 0;
    //       })
    //       //   console.log('Products', cart.cartTO.products)
    //       products.forEach(product => {
    //         // console.log('Product', product)
    //         // this.$store.commit('checkout/setOrderData', product)
    //         this.products.push(product)
    //         // let orderData = {
    //         //     "productTO": product.productTO,
    //         //       "currency":  this.$store.state.selectedCurrency,
    //         //       "discount": 0,
    //         //       "finalPrice": this.getTotalPrice(product.productTO, product.quantity),
    //         //       "price": this.getPrice(product.productTO),
    //         //       "quantity": product.quantity,
    //         //       "tax": 0,
    //         //       "taxRules": []  
    //         // }
    //         // this.$store.commit('checkout/setOrderData', orderData)
    //         // console.log('Products Data Array', this.products)
    //         // console.log('OrderData', orderData)
    //       })
    //     } else {
    //         // cart is being fetched in middleware on app load, so no need to fetch cart here in else
    //     //   this.$store.dispatch('getCart');
    //       console.log('getCart Else from CartPage component line 84 dispatch getCart Store')
    //     }
    //   },
      getPrice(price) {
        // console.log('Object Sent for Price in cart', price)
            let conversionDetails = price.conversionDetailsTO
            if(conversionDetails) {
                let cost = (price.priceTO.price + price.priceTO.tax) * conversionDetails.conversion
                let priceLabel = Math.round(cost)
                // console.log('Price Cart', priceLabel)
                return priceLabel
            }
      },
      getTotalPrice(productTO, quantity) {
        // console.log('Object Sent for Price in cart', productTO)
            let conversionDetails = productTO.conversionDetailsTO
            if(conversionDetails) {
                let cost = (productTO.priceTO.price + productTO.priceTO.tax) * conversionDetails.conversion
                let totalCost = Math.round(cost) * quantity
                // let priceLabel = totalCost + ' ' + conversionDetails.selectedCurrency
                // console.log('Price Cart', priceLabel)      
                // this.$store.commit('checkout/setTotalPrice', totalCost)   
                return totalCost
            } else {
                let totalCost = productTO.priceTO.price * quantity
                return totalCost
            }
      },
      updateQuantity(product) {
        console.log("Update Quantity from Cart", product)
        let operation = "QUANTITY_CHANGE_CART"
        this.$store.dispatch('cartUpdate', {"product": product, "operation": operation})
      },
      deleteProduct (product) {
        // console.log('Delete', product)
        let operation = "REMOVE_FROM_CART"
        this.$store.dispatch('cartUpdate', {"product": product, "operation": operation})
      },
      totalDue() {
        // console.log('Products', this.products)
        let grandTotal = 0
        if (this.products) {
            this.products.forEach(product => {
                grandTotal = grandTotal + this.getTotalPrice(product.productTO, product.quantity)
          })
          this.$store.commit('checkout/setTotalPrice', grandTotal)   
          this.$store.commit('checkout/setGrandTotal', grandTotal)
        }
        return grandTotal
      },
      getImagePath(product) {
          return constants.url + '/download?gsObjectName=' + product.productTO.imagePath
      },
      deleteProduct (product) {
        console.log('Delete', product)
        let operation = "REMOVE_FROM_CART"
        this.$store.dispatch('cartUpdate', {"product": product, "operation": operation})
      },
      totalDue() {
        // console.log('Products', this.products)
        let grandTotal = 0
        if (this.products) {
            this.products.forEach(product => {
                grandTotal = grandTotal + this.getTotalPrice(product.productTO, product.quantity)
          })
          this.$store.commit('checkout/setTotalPrice', grandTotal)   
          this.$store.commit('checkout/setGrandTotal', grandTotal)
        }
        return grandTotal
      }
    }
}
</script>
