import constants from '~/components/constants'

// L3mart Prod web app's Firebase configuration
var firebaseConfigProd = null

// L3mart Dev web app's Firebase configuration
var firebaseConfigDev = {
  apiKey: "AIzaSyCY-LbYTyYfIy1s_VaHHupZK9Y93ZWEdSo",
  authDomain: "sellstrong-dev.firebaseapp.com",
  databaseURL: "https://sellstrong-dev.firebaseio.com",
  projectId: "sellstrong-dev",
  storageBucket: "sellstrong-dev.appspot.com",
  messagingSenderId: "196334347309",
  appId: "1:196334347309:web:9910268e43e74fa90344a4"
};

function getFirebaseConfig() {
  return constants.isProd ? firebaseConfigProd : firebaseConfigDev;
}

export default getFirebaseConfig()