<template>
                <div class="header-dropdown ml-4">
        <a>{{val}}</a>
    <div class="header-menu">
        <ul>
            <li v-for="item in currencyItems" :key="item">
                <a @click="onChange(item)">{{item}}</a>
            </li>
        </ul>
    </div>
                </div>
</template>

<script>
    export default {
        props: [
            'value',
            'fieldLabel',
            'design1',
            'design2',
            'design3',
            'type'
        ],
        data () {
            return {
                val: this.$store.getters.activeSelectedCurrency,
                items: [],
                heightItems: ["", "1 inch", "2 inch", "3 inch", "4 inch"],
                currencyItems: this.$store.getters.activeMartCurrencies,
            }
        },
        watch: {
            val: function(newValue, oldValue) {
                this.$store.commit('setSelectedCurrency', this.val)
                this.$store.dispatch('getCart')
            }
        },
        created: function() {
            this.getMartCurrencies()
            if (this.type) {
                switch (this.type) {
                    case "height":
                        this.items = this.heightItems
                        break
                    case "currency":
                        this.items = this.currencyItems
                        break
                    default:
                        break
                }
            }
        },
        methods: {
            getMartCurrencies() {
                let userDefaultCurrency = this.$store.getters.activeMartInfo.userDefaultCurrency
                this.val = userDefaultCurrency ? userDefaultCurrency : this.$store.getters.activeMartInfo.currency
            },
            onChange(item) {
                this.val = item;
            }
        }
    }
</script>