import constants from "~/components/constants";
import axios from "axios";

// state
const state = {
  productList: null,
  productImages: [],
  producDefaultImage: null,
  singleProductDetails: null,
  pageCategories: [],
  pageProductList: []
};

// getters
const getters = {
  activeProductList: (state) => {
    return state.productList;
  },
  activeProduct: (state) => {
    return state.productImages;
  },
  getProductDefaultImage: (state) => {
    return state.producDefaultImage
  },
  singleProductDetails: (state) => {
    return state.singleProductDetails
  },
  getPageCategories: (state) => {
    return state.pageCategories
  }
};

// mutations
const mutations = {
  setProductList: (state, payload) => {
    state.productList = payload;
  },
  setProductImageUrl: (state, payload) => {
    // console.log(payload.fileTOs)
    let imageData = payload.fileTOs;
    imageData.forEach((element) => {
      if (element.displayOnCard == true) {
        let bucketName = element.bucketName
        let defaultImageName = element.gsfileNames.LARGE_PREVIEW
        state.producDefaultImage = constants.url + '/download?gsObjectName=' + bucketName + '/' + defaultImageName
        // parent.expandedImageUrl = constants.url + '/download?gsObjectName=' + bucketName + '/' + element.gsfileNames.DETAIL
        // console.log('Default Image Url', parent.defaultImageUrl)
      }
      let bucketName = element.bucketName;
      let thumbNailImages = {
        thumbUrl: constants.url + "/download?gsObjectName=" + bucketName + "/" + element.gsfileNames.THUMBNAIL,
        previewUrl: constants.url + "/download?gsObjectName=" + bucketName + "/" + element.gsfileNames.LARGE_PREVIEW,
        largeImageUrl: constants.url + "/download?gsObjectName=" + bucketName + "/" + element.gsfileNames.DETAIL,
      };
      // console.log('ProductDetails', thumbNailImages)
      state.productImages.push(thumbNailImages);
    });
  },
  setSingleProductDetails: (state, payload) => {
      // console.log('Product Details Payload', payload)
    state.singleProductDetails = payload;
  },
  destroyProductImageUrl: (state) => {
    state.productImages = []
    state.producDefaultImage = null
  },
  setPageCategories: (state, payload) => {
    state.pageCategories = payload
  },
  destroyPageCategories: (state, payload) => {
    state.pageCategories = []
  }
};

// actions
const actions = {
  // payload will be page Id
  getProductList: ({ commit, state, getters, rootState, rootGetters }, payload) => {
    let pageId = payload.pageId;
    let selectedCurrency = rootGetters.activeSelectedCurrency;
  },
  // TODO: below action is in complete, was done to make products, cats store and 
  // retrieve from store rather api call.. akshat@lounge3.com
  getPageProductList: ({commit, state, getters, rootState, rootGetters}, categories) => {
    // console.log('getPageProductList')
    if(categories) {
      categories.forEach((category) => {
        console.log('page category', category, rootState.selectedCurrency, rootGetters.activeMartInfo.id)
        let selectedCurrency = rootState.selectedCurrency
        let martId = rootGetters.activeMartInfo.id
        let page = 0
        let size = 3
        let CancelToken = null
        let source = null
        CancelToken = axios.CancelToken;
        source = CancelToken.source();
        // this.loadProducts(category.id, 0, 3, rootState.selectedCurrency)
        let url = constants.url + "/api/product" + "?martId=" + martId + "&categoryId=" + category.id + "&page=" + page + "&size=" + size + "&selectedCurrency=" + selectedCurrency;
        console.log('URL', url)
        axios.get(url, {withCredentials: true, cancelToken: source.token})
        .then((response) => {
          console.log('Response', response.data)
        })
      })
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
