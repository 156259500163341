import Vuex from 'vuex'
import constants from '~/components/constants'
import axios from 'axios'
import checkout from './modules/checkout'
import cart from './modules/cart'
import products from './modules/products'
import currencies from './modules/currencies'
import profile from './modules/profile'
import session from './modules/session'
import search from './modules/search'
import ghost from './modules/ghost'
import reviews from './modules/reviews'
// import firebase, {auth, GoogleProvider} from '@/services/fireinit.js'

const createStore = () => {
  return new Vuex.Store({
    modules: {
      checkout,
      cart,
      products,
      currencies,
      profile,
      session,
      search,
      ghost,
      reviews
    },
    state: {
      customer: null,
      productList: null,
      productCategories: null,
      martInfo: null,
      pagesData: null,
      selectedCurrency: null,
      // this is not used as of now, was used to store product details in store.
      productDetails: null,
      loaderCurrencyChange: null,
      cart: null,
      rightDrawer: false,
      // TODO this can be deleted if not in use for a while
      // loaderRightDrawer: false,
      // Cart Page items
      loaderCartPage: false,
      paymentMethods: null,
      selectedPaymentMethod: null,
      paymentMethodsLoader: false,
      addressNotSame: null,
      orderList: null,
      orderDetails: null,
      loaderCheckoutPage: false,
      // global SnackBar
      snackBar: {}
    },
    getters: {
      // activeProductList: (state, getters) => {
      //   return state.productList
      // },
      selectedPaymentMethod: (state) => {
        return state.selectedPaymentMethod
      },
      selectedPaymentGateway: (state) => {
        return state.selectedPaymentMethod ? state.selectedPaymentMethod.gateway : null
      },
      activeProductCategories: (state, getters) => {
        return state.productCategories
      },
      activeMartInfo: (state, getters) => {
        return state.martInfo
      },
      activePagesData: (state, getters) => {
        return state.pagesData
      },
      // Returns page object with matching page id sent as argument.
      getPage: (state) => (id) => {
        let pageObj = null
        let pages = state.pagesData
        // console.log('pages', pages, Number(id), typeof id, typeof Number(id))
        if (pages && id) {
          pages.forEach((page) => {
            // console.log('page id', page.id, typeof page.id)
            if (id == page.slug) {
              // console.log('page here')
              pageObj = page
            }
          })
        }
        return pageObj
      },
      activeSelectedCurrency: (state, getters) => {
          return state.selectedCurrency
      },
      // this code is not used, was used to store productdetails in store.
      activeProductDetails: (state, getters) => {
        return state.productDetails
      },
      activeCustomer: (state, getters) => {
        return state.customer
      },
      activeTempCustomer: (state, getters) => {
        if (state.customer && state.customer.temp) {
          return true
        }
      },
      getCurrencyChangeLoader: (state, getters) => {
        return state.loaderCurrency
      },
      activeCart: (state, getters) => {
        return state.cart
      },
      getRightDrawer: (state, getters) => {
        return state.rightDrawer
      },
      // TODO this can be deleted if not in use for a while
      // getLoaderRightDrawer: (state, getters) => {
      //   return state.loaderRightDrawer
      // },
      // Cart Page
        getLoaderCartPage: (state, getters) => {
          return state.loaderCartPage
        },
      activePaymentMethods: (state, getters) => {
        // console.log('Payment Methods', state.paymentMethods)
        // let sortedPaymentMethods = state.paymentMethods.sort((a,b) => (a.order > b.order) ? 1 :((b.order > a.order) ? -1 :0))
        // console.log('Sorted Payment Method', sortedPaymentMethods)
        return state.paymentMethods
      },
      getAddressNotSame: (state, getters) => {
        return state.addressNotSame
      },
      activeOrderList: (state, getters) => {
        return state.orderList
      },
      activeOrderDetails: (state, getters) => {
        return state.orderDetails
      },
      getSnackBar: (state, getters) => {
        return state.snackBar
      },
    },
    mutations: {
      // setProductList(state, payload) {
      //   state.productList = payload
      // },
      setProductCategories(state, payload) {
        state.productCategories = payload
      },
      setMartInfo(state, payload) {
        state.martInfo = payload
      },
      setPagesData(state, payload) {
        state.pagesData = payload
      },
      setSelectedCurrency(state, payload) {
        state.selectedCurrency = payload
      },
      setCustomer(state, payload) {
        state.customer = payload
      },
      // this code is not used right now, was made to get product details from actions below
      getProductDetails: (state, payload) => {
        // console.log('Data from actions', payload)
        state.productDetails = payload
      },
      setCurrencyChangeLoader(state, payload) {
        state.loaderCurrency = payload
        // console.log('Currency Loader State', payload)
      },
      setCart(state, payload) {
        state.cart = payload
      },
      setRightDrawer(state, payload) {
        state.rightDrawer = payload
      },
      // TODO this can be deleted if not in use for a while
      // setLoaderRightDrawer(state, payload) {
      //   state.loaderRightDrawer = payload
      // },
      // cart Page
      setLoaderCartPage(state, payload) {
        state.loaderCartPage = payload
      },
      setPaymentMethods(state, payload) {
        state.paymentMethods = payload
      },
      setSelectedPaymentMethod(state, payload) {
        state.selectedPaymentMethod = payload
      },
      setPaymentMethodsLoader(state, payload) {
        state.paymentMethodsLoader = payload
      },
      setAddressNotSame(state, payload) {
        state.addressNotSame = payload
      },
      setOrderList(state, payload) {
        state.orderList = payload
      },
      setOrderDetails(state, payload) {
        state.orderDetails = payload
      },
      // global snackbar
      setSnackBar(state, payload) {
        // payload should be an object with state, message
        // example: payload = {'state': true, 'message': 'user registered'}
        // console.log('Global SnackBar Set', payload)
        state.snackBar = payload
      },
    },
    actions: {
      // This code is not used now, was made to fetch products from actions
      async fetchProductDetails(context, productId) {
        let selectedCurrency = this.state.selectedCurrency
        let productUrl = constants.url + '/api/product/' + productId + '?selectedCurrency=' + selectedCurrency
        // console.log('Product Url Store', productUrl)
        try {
          const response = await axios.get(productUrl, { withCredentials: true });
          if (response.data) {
            context.commit('getProductDetails', response.data);
          }
        } catch (e) {
          console.log(e);
          commit('getProductDetails', payload);
        }
      },
      async getCart(context) {
        let selectedCurrency = this.state.selectedCurrency
        let martId = this.state.martInfo.id
        let customerId = this.state.customer.id
        let cartUrl = constants.url + '/api/cart?martId=' + martId + '&customerId=' + customerId + '&selectedCurrency=' + selectedCurrency

        // console.log('Cart Url', cartUrl)
        try {
          const response = await axios.get(cartUrl, { withCredentials: true });
          if (response.data) {
            // console.log('Get Cart Store Actions', response.data);
            context.commit('setCart', response.data);
            context.commit('setLoaderCartPage', false)
            context.dispatch('checkout/getCheckoutData')
          }
        } catch (e) {
          console.log('Error', e.response);
          // commit('setCart', payload);
        }
      },
      cartUpdate: function (context, data) {
        return new Promise((resolve, reject) => {
        context.commit('setLoaderCartPage', true)
        // this.state.loaderCheckoutPage = true
        // console.log('Quantity update Store', data.operation, data.product, data.qty)
        let selectedCurrency = this.state.selectedCurrency
        let martId = this.state.martInfo.id
        let customerId = this.state.customer.id
        let updateType = data.operation
        // let updateObject = data.updateObject
        let url = constants.url + '/api/cart?martId=' + martId + '&customerId=' + customerId + '&operation=' + updateType + '&selectedCurrency=' + selectedCurrency

        let updateObject = {
          // "quantity": data.qty,
          "quantity": data.product.quantity,
          "productTO": {
            "id": data.product.productTO.id,
            "martId": martId,
            "name": data.product.productTO.name
          }
        }
        console.log('Update Cart Quantity Object', updateObject, url)
        axios.put(url, updateObject, { withCredentials: true })
        .then(response => {
          console.log('Update Quantity Cart Store', response.data)
          context.commit('setCart', response.data);
          // context.commit('setLoaderRightDrawer', false)
          context.commit('setLoaderCartPage', false)
          context.dispatch('checkout/getCheckoutData')
          resolve()
        })
        .catch(err => {
          reject(err)
        })
      })
      },
      getPaymentMethods: function (context) {
        if(!context.state.paymentMethods) {
          context.commit('setPaymentMethodsLoader', true);
          // console.log('Get Payment Methods from Store')
          let martId = this.state.martInfo.id
          let url = constants.url + '/api/payment?martId=' + martId
          axios.get(url, {
              withCredentials: true
            })
            .then(response => {
                // console.log('Get Payment Gateway (Store) resonse', response.data)
                let sortedPaymentMethods = response.data.paymentMethods.sort((a,b) => (a.order > b.order) ? 1 :((b.order > a.order) ? -1 :0))
                context.commit('setPaymentMethods', sortedPaymentMethods);
                let defaultPaymentMethods = response.data.paymentMethods.filter(method => {
                  return method.default
                })
                if (defaultPaymentMethods && defaultPaymentMethods.length > 0) {
                  context.commit('setSelectedPaymentMethod', response.data.paymentMethods.filter(method => {
                    return method.default
                  })[0]);
            }
            context.commit('setPaymentMethodsLoader', false);
          })
        }
      },
      getOrderList: function (context) {
        // console.log('Get Payment Methods from Store')
        let martId = this.state.martInfo.id
        let customerId = this.state.customer.id
        let url = constants.url + '/api/order?martId=' + martId + '&customerId=' + customerId
        axios.get(url, { withCredentials: true })
        .then(response => {
          // console.log('Get Order List (Store) resonse', response.data)
          context.commit('setOrderList', response.data);
        })
      },
      getOrderDetails: function (context, orderId) {
        return new Promise((resolve, reject) => {
        let martId = this.state.martInfo.id
        let url = constants.url + '/api/order/' + orderId + '?martId=' + martId
        axios.get(url, { withCredentials: true })
        .then(response => {
          // console.log('Get Order Details (Store) response', response.data)
          context.commit('setOrderDetails', response.data);
          resolve()
        })
        .catch(error => {
          reject(error)
        })
        })
      },
      updateCustomer(context, payload) {
        let updateUrl = constants.url + '/api/customer'
        let customer = payload
        // console.log('Customer put in store', customer)
        axios.put(updateUrl, customer, { withCredentials: true })
        .then(response => {
          // console.log('Update Customer from Store', response.data)
          context.commit('setCustomer', response.data)
        })
      },
    }
  })
}

export default createStore