<template>
  <div>
    <a v-if="activeCustomer && !activeCustomer.temp" class="header-icon" data-toggle="modal" data-target="#modal" id="more">
      <i class="icon-user-2"></i>
      <!-- <i class="fas fa-user fa-2x"></i> -->
    </a>
    <!-- <a class="header-icon login-link" data-toggle="modal" data-target="#modal" id="more"> -->
    <a v-else class="header-icon" data-toggle="modal" data-target="#modal" id="more">
      <i class="icon-user-2"></i>
      <!-- <i class="far fa-user fa-2x"></i> -->
    </a>
    <div class="modal fade" tabindex="-1" id="modal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title">Manage Account</h5> -->
            <h5 v-if="activeCustomer && !activeCustomer.temp">Welcome {{ activeCustomer.name }}</h5>
            <a type="button" class="btn-close btn-link" data-dismiss="modal" aria-label="Close" style="cursor: pointer;">
              <i class="icon-cancel fa-lg"></i>
            </a>
          </div>
          <div class="modal-body">
            <div v-if="activeCustomer && activeCustomer.temp" class="container">
              <div class="row row-sparse">
                <div class="col-md-6">
                  <h2 class="title mb-2">Login or Register</h2>

                  <!-- <form action="#" class="mb-1"> -->
                  <label for="login-email">Email address <span class="required">*</span></label>
                  <input type="email" class="form-input form-wide mb-2" id="login-email" required v-model="email" />

                  <label for="login-password">Password <span class="required">*</span></label>
                  <input type="password" v-model="password" class="form-input form-wide mb-2" id="login-password" required />
                  <!-- <button @click="onLogin()">Login</button> -->
                  <p class="text-muted">To Login or Register, fill the fields above and hit the appropriate button below.</p>
                  <div v-show="registerUserExists" class="alert alert-danger" role="alert">
                    User with this ID already exists, try Login instead..
                  </div>
                  <div class="form-footer">
                    <button type="submit" class="btn btn-primary btn-md" @click="onLogin()">
                      LOGIN
                    </button>
                    <button type="submit" class="btn btn-secondary btn-md" @click="registerUser()">
                      Register
                    </button>

                    <div class="custom-control custom-checkbox form-footer-right">
                      <input type="checkbox" class="custom-control-input" id="lost-password" />
                      <label class="custom-control-label form-footer-right" for="lost-password">Remember Me</label>
                    </div>
                  </div>
                  <!-- End .form-footer -->
                  <a href="#" class="forget-password">Forgot your password?</a>
                  <!-- </form> -->
                </div>
              </div>
              <!-- End .row -->
            </div>
            <div v-else class="d-grid col-12 mx-auto">
              <NuxtLink to="#" type="button" class="btn btn-sm btn-outline-secondary">My Profile</NuxtLink>
              <NuxtLink to="/shop/orders" data-dismiss="modal" type="button" class="btn btn-sm btn-outline-secondary">My Orders</NuxtLink>
              <NuxtLink to="#" type="button" class="btn btn-sm btn-outline-secondary">Shipping Address</NuxtLink>
              <NuxtLink to="#" type="button" class="btn btn-sm btn-outline-secondary">Billing Address</NuxtLink>
              <span class="separator"></span>
              <button type="button" class="btn btn-primary btn-sm" @click="logout()">LOGOUT</button>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-sm btn-link" data-dismiss="modal">Close</button>
            <!-- <button type="button" class="btn btn-primary btn-sm">LOGOUT</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: [
        'loginDialog'
    ],
    data() {
        return {
            email: '',
            password: '',
            // rePassword: '',
            registerDialog: false,
            resetDialog: false,
            resetPasswordDialog: false,
            otpCode: '',
            newPassword: '',
            confirmPassword: '',
            otpFetchLoader: false,
            updatePwdLoader: false,
            rules: {
              otp: [val => (val.length == 6) || 'Enter valid OTP sent to your email'],
              newPassword: [val => (val && val.length >= 8)  || 'Enter a valid password, Atleast 8 characters long'],
              confirmPasswordRule: [val => (val === this.newPassword) || 'New password & confirm password fields do not match']
            },
            registerUserExists: false
        }
    },
    watch: {
        dialog(val) {
            !val && this.setLoginDialog()
        }
    },
    computed: {
        ...mapGetters(['getLoginBtnLoader', 'getLoginState', 'activeCustomer']),
        getLoginDialog: {
            get() {
                // console.log('Get Login Dialog', this.$store.state.session.loginDialog )
               return this.$store.getters.getLoginDialog
            },
            set(value) {
                this.$store.commit('setLoginDialog', value)
            }
        }
    },
    methods: {
        setLoginDialog() {
            this.$store.commit('setLoginDialog', false)
        },
        onLogin() {
            if (this.email && this.password) {
              // console.log(this.email, this.password)
                this.$store.commit('setLoginBtnLoader', true)
                let loginData = {
                    "email": this.email,
                    "password": this.password,
                }
                this.$store.dispatch('userLogin', loginData).then(() => {
                  // console.log('User Logged in')
                  this.$root.$emit('toast/show', {
                  title: 'Login Successful',
                  // message: '..',
                  timeout: 2000,
                  callback: (res) => {
                  console.log('toast callback::', res)
                  }
                  })
                })
                .catch((type) => {
                  console.log('Login error', type)
                    if (type == 'PASS-INCORRECT') {
                  this.$root.$emit('toast/show', {
                  title: 'Check Password',
                  message: 'Your password might be incorrect, try again or reset password!!',
                  timeout: 3000,
                  callback: (res) => {
                  console.log('toast callback::', res)
                  }
                  })
                  }
                if (type == 'NO-USER-EXIST') {
                   this.$root.$emit('toast/show', {
                  title: 'User does not exist',
                  message: 'Please register and try again!!',
                  timeout: 3000,
                  callback: (res) => {
                  console.log('toast callback::', res)
                  }
                  })
                }
                })
                $('#modal').modal('hide');
            } else {
                let snackBar = {
                    state: true,
                    message: 'Email & Password both are required'
                }
                this.$store.commit('setSnackBar', snackBar)
            }
        },
        registerBtn() {
            this.$store.commit('setLoginDialog', false)
            this.registerDialog = true
        },
        registerUser() {
            if (this.email && this.password) {
                this.$store.commit('setRegisterBtnLoading', true)
                let payload = {
                    'email': this.email,
                    'password': this.password,
                }
                this.$store.dispatch('registerUser', payload).then(() => {
                  this.$root.$emit('toast/show', {
                  title: 'User Registered!',
                  message: 'You are Registered & Logged in now..',
                  timeout: 4000,
                  callback: (res) => {
                  console.log('toast callback::', res)
                  }
                  })
                  $('#modal').modal('hide');
                })
                .catch((type) => {
                  if (type == 'CUSTOMER-EXIST') {
                    this.registerUserExists = true
                    this.$root.$emit('toast/show', {
                  title: 'User Exists!!',
                  message: 'User with this ID already exists, try loging in..',
                  timeout: 4000,
                  callback: (res) => {
                  console.log('toast callback::', res)
                  }
                  })
                  }
                })
                this.registerDialog = false
            } else {
               let snackBar = {
                    state:  true,
                    message: 'Email & Password are required'
                }
                this.$store.commit('setSnackBar', snackBar)
            }
        },
        passwordResetBtn() {
            this.$store.commit('setLoginDialog', false)
            this.resetDialog = true
        },
        sendOtp() {
          if(this.email) {
              this.otpFetchLoader = true
            this.$store.dispatch('sendOtp', this.email)
              .then(() => {
                this.otpFetchLoader = false
                this.resetDialog = false
                this.resetPasswordDialog = true
              })
              .catch(() => {
                // this.resetDialog = false
              })
          } else {
              let snackBar = {
                  state:  true,
                  message: 'Email required to reset Password'
              }
              this.$store.commit('setSnackBar', snackBar)
          }
        },
        updatePassword() {
          if (
            this.email
            && this.otpCode
            && this.newPassword
            && this.newPassword.length > 4
            && (this.newPassword === this.confirmPassword)) {
            this.$store.commit('setLoginBtnLoader', true)
            let loginData = {
              "email": this.email,
              "newPassword": this.newPassword,
              "otp": this.otpCode
            }
            // Update password and login
            this.updatePwdLoader = true
            this.$store.dispatch('userLogin', loginData)
              .then(() => {
                this.updatePwdLoader = false
                this.resetPasswordDialog = false
              })
              .catch(err => {
                if (err == 'NO-USER-EXIST') {
                  // NOOP
                  this.updatePwdLoader = false
                }
              })
          } else {
            this.$store.commit('setLoginBtnLoader', false)
            let snackBar = {
              state: true,
              message: 'A valid OTP code & Password is required.'
            }
            this.$store.commit('setSnackBar', snackBar)
          }
        },
        logout() {
          this.$store.dispatch('userLogout').then(() => {
            this.$root.$emit('toast/show', {
                  title: 'Logout Successful!!',
                  // message: '..',
                  timeout: 2000,
                  callback: (res) => {
                  console.log('toast callback::', res)
                  }
                  })
          })
          $('#modal').modal('hide');
          // this.$router.go()
        }
    }
}
</script>

<style scoped>
#more {
  cursor: pointer;
}
</style>
