<template>
  <!-- top banner position -->
  <div>
    <div class="top-notice">
      <div class="container">
        <div class="notify-left flex-wrap d-flex align-items-center">
          <h5 class="text-white"><strong>10% OFF</strong></h5>

          <div class="main-heading">
            <h5>Use <b class="text-dark">PORTO10</b> Coupon Code</h5>
            <h6><small>* Minimal Purchase Price</small></h6>
          </div>
        </div>

        <div class="notify-right d-block">
          <h4><b class="text-dark">FREE NEXT DAY DELIVERY*</b></h4>
          <h6><small>* On Business Days - On Orders Over $25</small></h6>
        </div>
      </div>
      <!-- End .container -->
    </div>
    <!-- End .top-notice-->
    <!-- <div class="page-wrapper">
      <div class="top-notice text-white bg-dark">
        <div class="container text-center">
          <h5 class="ls-n-10 mb-0">
            Get 10% extra OFF on Summer Sale - Use
            <b>PUNAMSUMMER</b> coupon - <a href="category.html">Shop Now!</a>
          </h5>
          <button title="Close (Esc)" type="button" class="mfp-close">×</button>
        </div>
        End .container
      </div>
      End .top-notice
    </div> -->
    <!--  -->
    <header class="header">
      <div class="header-top sticky-header">
        <div class="container">
          <div class="header-left header-dropdowns">
            <div class="header-dropdown">
              <a href="#" class="pl-0"
                ><img
                  src="website_internal/assets/images/flags/en.png"
                  alt="England flag"
                />Eng</a
              >
              <div class="header-menu">
                <ul>
                  <li>
                    <a href="#"
                      ><img
                        src="website_internal/assets/images/flags/en.png"
                        alt="England flag"
                      />Eng</a
                    >
                  </li>
                  <li>
                    <a href="#"
                      ><img
                        src="website_internal/assets/images/flags/fr.png"
                        alt="France flag"
                      />Frh</a
                    >
                  </li>
                </ul>
              </div>
              <!-- End .header-menu -->
            </div>
            <!-- End .header-dropdown -->
            <div class="header-dropdown ml-4">
              <a href="#">USD</a>
              <div class="header-menu">
                <ul>
                  <li><a href="#">EUR</a></li>
                  <li><a href="#">USD</a></li>
                </ul>
              </div>
            </div>
            <!-- End .header-menu -->

            <span class="separator"></span>

            <div class="header-menu">
              <ul class="mb-0 d-none d-lg-flex">
                <li><a href="#">Checkout</a></li>
                <li><a href="#">Cart</a></li>
                <li><a href="#">Contact Us</a></li>
              </ul>
            </div>

          </div>
          <div class="header-right w-lg-max ml-0 ml-lg-auto">
            <div
              class="header-contact d-none d-sm-flex align-items-center ml-auto"
            >
              <i class="icon-phone-2"></i>
              <h6><a href="tel:#" class="d-block">+123 5678 890</a></h6>
            </div>
            <!-- End .header-contact -->

            <span class="separator d-sm-block d-none"></span>

            <div
              class="header-search header-search-popup header-search-category d-block header-icon text-dark"
            >
              <a
                href="#"
                class="search-toggle btn p-0 icon-search-3"
                role="button"
              >
              </a>

              <form action="#" method="get">
                <div class="header-search-wrapper">
                  <input
                    type="search"
                    class="form-control bg-white"
                    name="q"
                    id="q"
                    placeholder="Search..."
                    required=""
                  />
                  <button
                    class="btn fas fa-search bg-transparent p-0 border-0"
                    type="submit"
                  ></button>
                </div>
                <!-- End .header-search-wrapper -->
              </form>
            </div>

            <a href="login.html" class="header-icon login-link text-dark"
              ><i class="icon-user-2"></i
            ></a>

            <a href="#" class="header-icon position-relative">
              <i class="icon-wishlist-2 text-dark"></i>
              <span class="badge-circle">0</span>
            </a>

            <div class="dropdown cart-dropdown pl-1 text-dark">
              <a
                href="#"
                class="dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-display="static"
              >
                <i class="icon-shopping-cart"></i>
                <span class="cart-count badge-circle">2</span>
              </a>

              <div class="dropdown-menu">
                <div class="dropdownmenu-wrapper">
                  <div class="dropdown-cart-header">
                    <span>2 Items</span>

                    <a href="cart.html" class="float-right">View Cart</a>
                  </div>
                  <!-- End .dropdown-cart-header -->

                  <div class="dropdown-cart-products">
                    <div class="product">
                      <div class="product-details">
                        <h4 class="product-title">
                          <a href="product.html">Woman Ring</a>
                        </h4>

                        <span class="cart-product-info">
                          <span class="cart-product-qty">1</span>
                          x $99.00
                        </span>
                      </div>
                      <!-- End .product-details -->

                      <figure class="product-image-container">
                        <a href="product.html" class="product-image">
                          <img
                            src="assets/images/products/cart/product-1.jpg"
                            alt="product"
                            width="80"
                            height="80"
                          />
                        </a>
                        <a
                          href="#"
                          class="btn-remove icon-cancel"
                          title="Remove Product"
                        ></a>
                      </figure>
                    </div>
                    <!-- End .product -->

                    <div class="product">
                      <div class="product-details">
                        <h4 class="product-title">
                          <a href="product.html">Woman Necklace</a>
                        </h4>

                        <span class="cart-product-info">
                          <span class="cart-product-qty">1</span>
                          x $35.00
                        </span>
                      </div>
                      <!-- End .product-details -->

                      <figure class="product-image-container">
                        <a href="product.html" class="product-image">
                          <img
                            src="assets/images/products/cart/product-2.jpg"
                            alt="product"
                            width="80"
                            height="80"
                          />
                        </a>
                        <a
                          href="#"
                          class="btn-remove icon-cancel"
                          title="Remove Product"
                        ></a>
                      </figure>
                    </div>
                    <!-- End .product -->
                  </div>
                  <!-- End .cart-product -->

                  <div class="dropdown-cart-total">
                    <span>Total</span>

                    <span class="cart-total-price float-right">$134.00</span>
                  </div>
                  <!-- End .dropdown-cart-total -->

                  <div class="dropdown-cart-action">
                    <a
                      href="checkout-shipping.html"
                      class="btn btn-dark btn-block"
                      >Checkout</a
                    >
                  </div>
                  <!-- End .dropdown-cart-total -->
                </div>
                <!-- End .dropdownmenu-wrapper -->
              </div>
              <!-- End .dropdown-menu -->
            </div>
            <!-- End .dropdown -->
          </div>
          <!-- End .header-right -->
          <!-- right icon -->
          <!-- End .header-right -->
          <!-- body container  -->
        </div>
      </div>
      <!-- Menu -->
      <div class="header-middle sticky-header">
        <div class="container">
          <div class="header-left d-none d-lg-flex">
            <div class="main-nav d-flex w-lg-max">
              <ul class="menu">
                <li class="active">
                  <a href="/test">Home</a>
                </li>
                <li class="active">
                  <a href="/about-us">About</a>
                </li>

                <li class="border-0">
                  <a href="/shop" class="sf-with-ul border-0">Shop</a>
                  <div class="megamenu megamenu-fixed-width megamenu-3cols">
                    <div class="row">
                      <div class="col-lg-9">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="menu-title mb-1">
                              <a href="#"
                                >Flute Categories<span class="tip tip-new"
                                  >New!</span
                                ></a
                              >
                            </div>
                            <ul class="submenu">
                              <li>
                                <a href="category-banner-full-width.html"
                                  >Beginer's Flute<span class="tip tip-hot"
                                    >Hot!</span
                                  ></a
                                >
                              </li>
                              <li>
                                <a href="category-banner-boxed-slider.html"
                                  >Concert Quality</a
                                >
                              </li>
                              <li>
                                <a href="category-banner-boxed-image.html"
                                  >Right Handed Players</a
                                >
                              </li>
                              <li>
                                <a href="category-sidebar-left.html"
                                  >Left Handed Players</a
                                >
                              </li>
                              <li>
                                <a href="category-sidebar-right.html"
                                  >Flute Sets</a
                                >
                              </li>
                              <li>
                                <a href="category-flex-grid.html"
                                  >Carnatic Flutes</a
                                >
                              </li>
                              <li>
                                <a href="category-horizontal-filter1.html"
                                  >Bansuri Flutes 432Hz</a
                                >
                              </li>
                              <li>
                                <a href="category-horizontal-filter2.html"
                                  >Combo Flute Sets</a
                                >
                              </li>
                            </ul>
                          </div>
                          <!-- End .col-lg-6 -->
                          <!-- <div class="col-lg-6">
                            <div class="menu-title mb-1">
                              <a href="#">Popular Flutes</a>
                            </div>
                            <ul class="submenu">
                              <li><a href="#">G nati</a></li>
                              <li>
                                <a href="category-infinite-scroll.html"
                                  >Ajax Infinite Scroll</a
                                >
                              </li>
                              <li>
                                <a href="category-3col.html"
                                  >3 Columns Products</a
                                >
                              </li>
                              <li>
                                <a href="category.html"
                                  >4 Columns Products
                                  <span class="tip tip-new">New</span></a
                                >
                              </li>
                              <li>
                                <a href="category-5col.html"
                                  >5 Columns Products</a
                                >
                              </li>
                              <li>
                                <a href="category-6col.html"
                                  >6 Columns Products</a
                                >
                              </li>
                              <li>
                                <a href="category-7col.html"
                                  >7 Columns Products</a
                                >
                              </li>
                              <li>
                                <a href="category-8col.html"
                                  >8 Columns Products</a
                                >
                              </li>
                            </ul>
                          </div> -->
                          <!-- End .col-lg-6 -->
                        </div>
                        <!-- End .row -->
                      </div>
                      <!-- End .col-lg-9 -->
                      <!-- <div class="col-lg-3 p-0">
                        <div class="banner h-100">
                          <a href="#">
                            <img
                              src="website_internal/assets/images/menu-banner-2.jpg"
                              alt="Menu banner"
                            />
                          </a>
                        </div>
                        End .banner
                      </div> -->
                      <!-- End .col-lg-3 -->
                    </div>
                  </div>
                  <!-- End .megamenu -->
                </li>

                <li>
                  <a href="#">Information</a>
                  <ul>
                    <li><a href="cart.html">Shopping Cart</a></li>
                    <li>
                      <a href="#">Checkout</a>
                      <ul>
                        <li>
                          <a href="checkout-shipping.html">Checkout Shipping</a>
                        </li>
                        <li>
                          <a href="checkout-shipping-2.html"
                            >Checkout Shipping 2</a
                          >
                        </li>
                        <li>
                          <a href="checkout-review.html">Checkout Review</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Dashboard</a>
                      <ul>
                        <li><a href="dashboard.html">Dashboard</a></li>
                        <li><a href="my-account.html">My Account</a></li>
                      </ul>
                    </li>
                    <li><a href="about.html">About Us</a></li>
                    <li>
                      <a href="#">Blog</a>
                      <ul>
                        <li><a href="blog.html">Blog</a></li>
                        <li><a href="single.html">Blog Post</a></li>
                      </ul>
                    </li>
                    <li><a href="contact.html">Contact Us</a></li>
                    <li><a href="#" class="login-link">Login</a></li>
                    <li><a href="forgot-password.html">Forgot Password</a></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <!-- End .header-left -->

          <div class="header-center justify-content-between">
            <a href="/" class="logo">
              <img src="website_internal/assets/images/logo9.png" alt="Porto Logo" />
            </a>

            <button class="mobile-menu-toggler" type="button">
              <i class="icon-menu"></i>
            </button>
          </div>
          <!-- End .header-center -->

          <div class="header-right d-none d-lg-flex">
            <div class="main-nav d-flex w-lg-max justify-content-end">
              <ul class="menu">
                <li><a href="#">Blog</a></li>
                <li><a href="#">Stores</a></li>
                <li><a href="#">Returns</a></li>
                <li>
                  <a href="#" target="_blank"
                    >Subscribe</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- End .header-right -->
        </div>
        <!-- End .container -->
      </div>
      <!-- End .header-middle -->
    </header>
    <nuxt />
  </div>
</template>

<script>
export default {
  mounted: function () {
    // document.body.classList.add('btn-rounded')
    // document.body.classList.add('scroll-assist')
    // this.loadScript('/website_internal/assets/js/main.min.js')
    // this.loadScript('/website_internal/assets/js/jquery.min.js')
    // this.loadScript("/website_internal/assets/js/bootstrap.bundle.min.js");
    // this.loadScript("/website_internal/assets/js/plugins.min.js");
    // this.loadScript('/website/js/bootstrap.min.js')
    // this.loadScript('/website/js/flexslider.min.js')
    // this.loadScript('/website/js/twitterfetcher.min.js')
    // this.loadScript('/website/js/parallax.js')
    // this.loadScript('/website/js/scripts.js')
  },
  methods: {
    loadScript(src) {
      let tag = document.createElement("script");
      tag.src = src;
      document.body.appendChild(tag);
      //   WebFontConfig = {
      // 		google: { families: [ 'Open+Sans:300,400,600,700,800','Poppins:300,400,500,600,700' ] }
      // 	};
      // 	(function(d) {
      // 		var wf = d.createElement('script'), s = d.scripts[0];
      // 		wf.src = src
      // 		wf.async = true;
      // 		s.parentNode.insertBefore(wf, s);
      // 	})(document);
    },
  },
  head() {
    return {
      title: "Punam Flutes - Online Flute",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Punam Flutes, Indian Bamboo Flutes (Bansuri)",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, shrink-to-fit=no",
        },
      ],
      link: [
        // {
        //   rel: "stylesheet",
        //   href: "/website_internal/assets/css/bootstrap.min.css",
        //   type: "text/css",
        //   media: "all",
        // },
        {
          rel: "stylesheet",
          href: "/website_internal/assets/css/animate.min.css",
          type: "text/css",
          media: "all",
        },
        {
          rel: "stylesheet",
          href: "/website_internal/assets/css/style.min.css",
          type: "text/css",
          media: "all",
        },
        // {
        //   rel: "stylesheet",
        //   href:
        //     "/website_internal/assets/vendor/fontawesome-free/css/all.min.css",
        //   type: "text/css",
        //   media: "all",
        // },
        //  { rel: 'stylesheet', href: '/website/css/flexslider.css', type: 'text/css', media: 'all' },
        // //  { rel: 'stylesheet', href: '/website/css/lightbox.min.css', type: 'text/css', media: 'all' },
        // //  { rel: 'stylesheet', href: '/website/css/ytplayer.css', type: 'text/css', media: 'all' },
        //  { rel: 'stylesheet', href: '/website/css/theme-nature.css', type: 'text/css', media: 'all' },
        // //  { rel: 'stylesheet', href: '/website/css/theme.css', type: 'text/css', media: 'all' },
        //  { rel: 'stylesheet', href: '/website/css/custom.css', type: 'text/css', media: 'all' },
        //  { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Lato:300,400%7CRaleway:100,400,300,500,600,700%7COpen+Sans:400,500,600', type: 'text/css'},
        //  { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Open+Sans:100,300,400,600,700', type: 'text/css'},
        //  { rel: 'stylesheet', href: '/website/css/font-opensans.css', type: 'text/css', media: 'all' }
      ],
      script: [
        // { src: "/website_internal/assets/js/webfont.js" },
        // { src: "/website_internal/assets/js/jquery.min.js" },
        // { src: "/website_internal/assets/js/jquery.appear.min.js" },
        // { src: "/website_internal/assets/js/main.min.js" },
        // { src: "/website_internal/assets/js/bootstrap.bundle.min.js" },
        // { src: "/website_internal/assets/js/plugins.min.js" },
        
        // { src: '/website/js/bootstrap.min.js'},
        // { src: '/website/js/flexslider.min.js'},
        // { src: '/website/js/twitterfetcher.min.js'},
        // { src: '/website/js/scripts.js'},
        // { src: '/__/firebase/6.3.4/firebase-app.js', type: "text/javascript"},
        // { src: '/__/firebase/init.js', type: "text/javascript"}
      ],
    };
  },
};
</script>
