<template>
  <!-- top banner position -->
  <div class="page-wrapper">
    
    <!-- End .top-notice -->
    <!--  -->
    <header class="header">
      <div class="header-middle sticky-header">
        <div class="container">
          <div class="header-left">
            <a href="/" class="logo">
              <img src="website/assets/images/logo9.png" alt="Punam Flutes" />
            </a>

            <nav class="main-nav font2">
              <ul class="menu">
                <li>
                  <a href="/test">Home</a>
                </li>
                <li>
                  <a href="/about-us">About</a>
                  <!-- <div class="megamenu megamenu-fixed-width megamenu-3cols">
                        <div class="row">
                            <div class="col-lg-4">
                                <a href="#" class="nolink">VARIATION 1</a>
                                <ul class="submenu">
									<li><a href="category.html">Fullwidth Banner</a></li>
									<li><a href="category-banner-boxed-slider.html">Boxed Slider Banner</a></li>
									<li><a href="category-banner-boxed-image.html">Boxed Image Banner</a></li>
									<li><a href="category.html">Left Sidebar</a></li>
									<li><a href="category-sidebar-right.html">Right Sidebar</a></li>
									<li><a href="category-flex-grid.html">Product Flex Grid</a></li>
									<li><a href="category-horizontal-filter1.html">Horizontal Filter1</a></li>
									<li><a href="category-horizontal-filter2.html">Horizontal Filter2</a></li>
								</ul>
                            </div>
                        </div>
                    </div> -->
                </li>
                <!-- This last Li tag will be static URL of internal shop folder..  -->
                <li>
                  <a href="/shop">Shop</a>
                </li>
              </ul>
            </nav>
          </div>
          <!-- right icon -->
          <div class="header-right">
            <button class="mobile-menu-toggler" type="button">
              <i class="icon-menu"></i>
            </button>

            <a href="login.html" class="header-icon login-link"
              ><i class="icon-user-2"></i
            ></a>

            <a href="#" class="header-icon"><i class="icon-wishlist-2"></i></a>

            <div
              class="header-search header-search-popup header-search-category d-none d-sm-block"
            >
              <a href="#" class="search-toggle" role="button"
                ><i class="icon-magnifier"></i
              ></a>
              <form action="#" method="get">
                <div class="header-search-wrapper">
                  <input
                    type="search"
                    class="form-control"
                    name="q"
                    id="q"
                    placeholder="I'm searching for..."
                    required=""
                  />
                  <div class="select-custom">
                    <select id="cat" name="cat">
                      <option value="">All Categories</option>
                      <option value="4">Fashion</option>
                      <option value="12">- Women</option>
                      <option value="13">- Men</option>
                      <option value="66">- Jewellery</option>
                      <option value="67">- Kids Fashion</option>
                      <option value="5">Electronics</option>
                      <option value="21">- Smart TVs</option>
                      <option value="22">- Cameras</option>
                      <option value="63">- Games</option>
                      <option value="7">Home &amp; Garden</option>
                      <option value="11">Motors</option>
                      <option value="31">- Cars and Trucks</option>
                      <option value="32">
                        - Motorcycles &amp; Powersports
                      </option>
                      <option value="33">- Parts &amp; Accessories</option>
                      <option value="34">- Boats</option>
                      <option value="57">- Auto Tools &amp; Supplies</option>
                    </select>
                  </div>
                  <!-- End .select-custom -->
                  <button
                    class="btn bg-dark icon-search-3"
                    type="submit"
                  ></button>
                </div>
                <!-- End .header-search-wrapper -->
              </form>
            </div>

            <div class="dropdown cart-dropdown">
              <a
                href="#"
                class="dropdown-toggle dropdown-arrow"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-display="static"
              >
                <i class="icon-shopping-cart"></i>
                <span class="cart-count badge-circle">2</span>
              </a>

              <div class="dropdown-menu">
                <div class="dropdownmenu-wrapper">
                  <div class="dropdown-cart-header">
                    <span>2 Items</span>

                    <a href="cart.html" class="float-right">View Cart</a>
                  </div>
                  <!-- End .dropdown-cart-header -->

                  <div class="dropdown-cart-products">
                    <div class="product">
                      <div class="product-details">
                        <h4 class="product-title">
                          <a href="product.html">Woman Ring</a>
                        </h4>

                        <span class="cart-product-info">
                          <span class="cart-product-qty">1</span>
                          x $99.00
                        </span>
                      </div>
                      <!-- End .product-details -->

                      <figure class="product-image-container">
                        <a href="product.html" class="product-image">
                          <img
                            src="website/assets/images/products/cart/product-1.jpg"
                            alt="product"
                            width="80"
                            height="80"
                          />
                        </a>
                        <a
                          href="#"
                          class="btn-remove icon-cancel"
                          title="Remove Product"
                        ></a>
                      </figure>
                    </div>
                    <!-- End .product -->

                    <div class="product">
                      <div class="product-details">
                        <h4 class="product-title">
                          <a href="product.html">Woman Necklace</a>
                        </h4>

                        <span class="cart-product-info">
                          <span class="cart-product-qty">1</span>
                          x $35.00
                        </span>
                      </div>
                      <!-- End .product-details -->

                      <figure class="product-image-container">
                        <a href="product.html" class="product-image">
                          <img
                            src="website/assets/images/products/cart/product-2.jpg"
                            alt="product"
                            width="80"
                            height="80"
                          />
                        </a>
                        <a
                          href="#"
                          class="btn-remove icon-cancel"
                          title="Remove Product"
                        ></a>
                      </figure>
                    </div>
                    <!-- End .product -->
                  </div>
                  <!-- End .cart-product -->

                  <div class="dropdown-cart-total">
                    <span>Total</span>

                    <span class="cart-total-price float-right">$134.00</span>
                  </div>
                  <!-- End .dropdown-cart-total -->

                  <div class="dropdown-cart-action">
                    <a
                      href="checkout-shipping.html"
                      class="btn btn-primary btn-block"
                      >Checkout</a
                    >
                  </div>
                  <!-- End .dropdown-cart-total -->
                </div>
                <!-- End .dropdownmenu-wrapper -->
              </div>
              <!-- End .dropdown-menu -->
            </div>
            <!-- End .dropdown -->
          </div>
          <!-- End .header-right -->
          <!-- body container  -->
        </div>
      </div>
    </header>
    <nuxt />
  </div>
</template>

<script>
export default {
  mounted: function () {
    // document.body.classList.add('btn-rounded')
    // document.body.classList.add('scroll-assist')
    // this.loadScript('/website/assets/js/main.min.js')
    // this.loadScript('/website/assets/js/jquery.min.js')
    // this.loadScript("/website/assets/js/bootstrap.bundle.min.js");
    // this.loadScript("/website/assets/js/plugins.min.js");
    // this.loadScript('/website/js/bootstrap.min.js')
    // this.loadScript('/website/js/flexslider.min.js')
    // this.loadScript('/website/js/twitterfetcher.min.js')
    // this.loadScript('/website/js/parallax.js')
    // this.loadScript('/website/js/scripts.js')
  },
  methods: {
    loadScript(src) {
      let tag = document.createElement("script");
      tag.src = src;
      document.body.appendChild(tag);
      //   WebFontConfig = {
      // 		google: { families: [ 'Open+Sans:300,400,600,700,800','Poppins:300,400,500,600,700' ] }
      // 	};
      // 	(function(d) {
      // 		var wf = d.createElement('script'), s = d.scripts[0];
      // 		wf.src = src
      // 		wf.async = true;
      // 		s.parentNode.insertBefore(wf, s);
      // 	})(document);
    },
  },
  head() {
    return {
      title: "Punam Flutes - Online Flute",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: "Punam Flutes, Indian Bamboo Flutes (Bansuri)",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1, shrink-to-fit=no",
        },
      ],
      link: [
        {
          rel: "stylesheet",
          href: "/website/assets/css/bootstrap.min.css",
          type: "text/css",
          media: "all",
        },
        {
          rel: "stylesheet",
          href: "/website/assets/css/style.css",
          type: "text/css",
          media: "all",
        },
        {
          rel: "stylesheet",
          href: "/website/assets/vendor/fontawesome-free/css/all.min.css",
          type: "text/css",
          media: "all",
        },
        //  { rel: 'stylesheet', href: '/website/css/flexslider.css', type: 'text/css', media: 'all' },
        // //  { rel: 'stylesheet', href: '/website/css/lightbox.min.css', type: 'text/css', media: 'all' },
        // //  { rel: 'stylesheet', href: '/website/css/ytplayer.css', type: 'text/css', media: 'all' },
        //  { rel: 'stylesheet', href: '/website/css/theme-nature.css', type: 'text/css', media: 'all' },
        // //  { rel: 'stylesheet', href: '/website/css/theme.css', type: 'text/css', media: 'all' },
        //  { rel: 'stylesheet', href: '/website/css/custom.css', type: 'text/css', media: 'all' },
        //  { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Lato:300,400%7CRaleway:100,400,300,500,600,700%7COpen+Sans:400,500,600', type: 'text/css'},
        //  { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Open+Sans:100,300,400,600,700', type: 'text/css'},
        //  { rel: 'stylesheet', href: '/website/css/font-opensans.css', type: 'text/css', media: 'all' }
      ],
      script: [
        { src: "/website/assets/js/webfont.js" },
        { src: "/website/assets/js/jquery.min.js" },
        { src: "/website/assets/js/main.min.js" },
        { src: "/website/assets/js/bootstrap.bundle.min.js" },
        { src: "/website/assets/js/plugins.min.js" },
        { src: "/website/assets/js/nouislider.min.js" },
        // { src: '/website/js/bootstrap.min.js'},
        // { src: '/website/js/flexslider.min.js'},
        // { src: '/website/js/twitterfetcher.min.js'},
        // { src: '/website/js/scripts.js'},
        // { src: '/__/firebase/6.3.4/firebase-app.js', type: "text/javascript"},
        // { src: '/__/firebase/init.js', type: "text/javascript"}
      ],
    };
  },
};
</script>
