<template>
    <v-layout>
        <v-dialog v-model="getLoginDialog" width="500" persistent>
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    LOGIN
                </v-card-title>
                <v-card-text>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <v-text-field solo label="Email" type="email" v-model="email"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field solo label="Password" type="password" v-model="password"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-layout row wrap>
                        <v-flex>
                            <v-btn color="brown" :loading="getLoginBtnLoader" dark @click="onLogin()">
                                Login
                            </v-btn>
                            <v-btn color="brown" dark flat @click="registerBtn()">
                                Register
                            </v-btn>
                            <v-btn color="brown" dark flat @click="passwordResetBtn()">
                                Forget password
                            </v-btn>
                        </v-flex>
                        <v-flex>
                            <v-btn color="brown" flat @click="setLoginDialog()">
                                Close
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- User Register Dialog -->
        <v-dialog v-model="registerDialog" width="500" persistent>
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    REGISTER
                </v-card-title>
                <v-card-text>
                    <v-layout row wrap>
                        <v-flex xs12>
                            <v-text-field solo label="Email" type="email" v-model="email"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field solo label="Choose Password" type="password" v-model="password"></v-text-field>
                        </v-flex>
                        <!-- <v-flex xs12>
                            <v-text-field solo label="Re-type Password" type="password" v-model="rePassword"></v-text-field>
                        </v-flex> -->
                    </v-layout>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="brown" flat @click="registerDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="brown" dark @click="registerUser">
                        Register
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- User Pasword Reset - send OTP-->
        <v-dialog v-model="resetDialog" width="500" persistent>
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    PASSWORD RESET
                </v-card-title>
                <v-card-text>
                    <v-layout row wrap>
                        <v-flex>
                            <h3 class="caption font-weight-bold">OTP will be sent to this Email address to reset your password.</h3>
                        </v-flex>
                        <v-flex xs12 pt-3>
                            <v-text-field solo required label="Email" type="email" v-model="email"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="brown" flat @click="resetDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="brown" :loading="otpFetchLoader" dark @click="sendOtp">
                        Next
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- User Pasword Reset - final step -->
        <v-dialog v-model="resetPasswordDialog" width="500" persistent>
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    NEW PASSWORD
                </v-card-title>
                <v-card-text>
                    <v-layout row wrap>
                        <v-flex>
                            <h2
                              class="caption font-weight-bold"
                              style="background: yellow;">
                              Enter the OTP code sent to your email.
                            </h2>
                        </v-flex>
                        <v-flex xs12 pt-3>
                            <v-text-field solo required label="OTP code" type="number" v-model="otpCode" :rules="rules.otp"></v-text-field>
                            <v-text-field solo required label="New password" type="password" v-model="newPassword" :rules="rules.newPassword"></v-text-field>
                            <v-text-field solo required label="Confirm Password" type="password" v-model="confirmPassword" :rules="rules.confirmPasswordRule"></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="brown" flat @click="resetPasswordDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="brown" :loading="updatePwdLoader" dark @click="updatePassword">
                        Update password & Login
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import constants from '~/components/constants'
import axios from 'axios'
import {mapGetters} from 'vuex'

export default {
    props: [
        'loginDialog'
    ],
    data() {
        return {
            email: '',
            password: '',
            // rePassword: '',
            registerDialog: false,
            resetDialog: false,
            resetPasswordDialog: false,
            otpCode: '',
            newPassword: '',
            confirmPassword: '',
            otpFetchLoader: false,
            updatePwdLoader: false,
            rules: {
              otp: [val => (val.length == 6) || 'Enter valid OTP sent to your email'],
              newPassword: [val => (val && val.length >= 8)  || 'Enter a valid password, Atleast 8 characters long'],
              confirmPasswordRule: [val => (val === this.newPassword) || 'New password & confirm password fields do not match']
            }
        }
    },
    watch: {
        dialog(val) {
            !val && this.setLoginDialog()
        }
    },
    computed: {
        ...mapGetters(['getLoginBtnLoader']),
        getLoginDialog: {
            get() {
                // console.log('Get Login Dialog', this.$store.state.session.loginDialog )
               return this.$store.getters.getLoginDialog
            },
            set(value) {
                this.$store.commit('setLoginDialog', value)    
            }
        }
    },
    methods: {
        setLoginDialog() {
            this.$store.commit('setLoginDialog', false)            
        },
        onLogin() {
            if (this.email && this.password) {
                this.$store.commit('setLoginBtnLoader', true)
                let loginData = {
                    "email": this.email,
                    "password": this.password,
                }
                this.$store.dispatch('userLogin', loginData)
            } else {
                let snackBar = {
                    state: true,
                    message: 'Email & Password both are required'
                }
                this.$store.commit('setSnackBar', snackBar)
            }
        },
        registerBtn() {
            this.$store.commit('setLoginDialog', false)
            this.registerDialog = true
        },
        registerUser() {
            if (this.email && this.password) {
                this.$store.commit('setRegisterBtnLoading', true)
                let payload = {
                    'email': this.email,
                    'password': this.password,
                }
                this.$store.dispatch('registerUser', payload)
                this.registerDialog = false
            } else {
               let snackBar = {
                    state:  true, 
                    message: 'Email & Password are required'
                }
                this.$store.commit('setSnackBar', snackBar)
            }
        },
        passwordResetBtn() {
            this.$store.commit('setLoginDialog', false)
            this.resetDialog = true
        },
        sendOtp() {
          if(this.email) {
              this.otpFetchLoader = true
            this.$store.dispatch('sendOtp', this.email)
              .then(() => {
                this.otpFetchLoader = false
                this.resetDialog = false
                this.resetPasswordDialog = true
              })
              .catch(() => {
                // this.resetDialog = false
              })
          } else {
              let snackBar = {
                  state:  true, 
                  message: 'Email required to reset Password'
              }
              this.$store.commit('setSnackBar', snackBar)
          }
        },
        updatePassword() {
          if (
            this.email
            && this.otpCode
            && this.newPassword
            && this.newPassword.length > 4
            && (this.newPassword === this.confirmPassword)) {
            this.$store.commit('setLoginBtnLoader', true)
            let loginData = {
              "email": this.email,
              "newPassword": this.newPassword,
              "otp": this.otpCode
            }
            // Update password and login
            this.updatePwdLoader = true
            this.$store.dispatch('userLogin', loginData)
              .then(() => {
                this.updatePwdLoader = false
                this.resetPasswordDialog = false
              })
              .catch(err => {
                if (err == 'NO-USER-EXIST') {
                  // NOOP
                  this.updatePwdLoader = false
                }
              })
          } else {
            this.$store.commit('setLoginBtnLoader', false)
            let snackBar = {
              state: true,
              message: 'A valid OTP code & Password is required.'
            }
            this.$store.commit('setSnackBar', snackBar)
          }
        }
    }
}
</script>