const state = {
    
}

const getters = {
    
}

export default {
    state,
}