import currencyConst from '../../components/Constants/CurrencyConst';

const state = {
    martCurrencies: []
}

const getters = {
    activeMartCurrencies: (state) => {
        return state.martCurrencies
    },
    selCurrDispName: (state, getters) => {
        let selCurrency = getters.activeSelectedCurrency
        if (selCurrency) {
            let currencyObject = currencyConst.currencies[selCurrency]
            return currencyObject.displayName
        }
    },
    selCurrSymbol: (state, getters) => {
        let selCurrency = getters.activeSelectedCurrency
        if (selCurrency) {
            let currencyObject = currencyConst.currencies[selCurrency]
            return currencyObject.symbol
        }
    },
    selCurrCode: (state, getters) => {
        let selCurrency = getters.activeSelectedCurrency
        if (selCurrency) {
            let currencyObject = currencyConst.currencies[selCurrency]
            return currencyObject.code
        }
    }
}

const mutations = {
    setMartCurrencies: (state, payload) => {
        state.martCurrencies  = payload
    }
}

const actions = {
    
}

export default {
    state,
    getters,
    mutations,
    actions
}